import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import ExportReport from "../../../../../common/img/Xls.png";
import { DecryptToken } from "../../../../../common/helper";
import RangeDateReportModal from "../../Model/RangeDateReportModal";
import { toast } from "react-toastify";
import { BASE, CallGETAPI, CallPOSTAPI } from "../../../../../common/services";

const AedReportButton = ({
  exportReport = 1,
  accountId = "",
  siteId = "",
  EquipmentData = {},
}) => {
  const navigate = useNavigate();
  const user = DecryptToken();
  const [reportModal, setReportModal] = useState(false);
  const [apiEndPoint, setApiEndPoint] = useState("");
  const [tab, setTab] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [count, setCount] = useState(0);

  const AEDCount = EquipmentData?.aeds;
  const AccessoryCount = EquipmentData?.accessory;

  const fetchOnLoad = async () => {
    const res = await CallGETAPI(
      `account/aed-accessory-count?account_id=${accountId}&site_id=${siteId}`
    );
    setCount(res?.data?.data);
    console.log({ res });
  };

  useEffect(() => {
    fetchOnLoad();
    console.log({ count });
  }, []);

  const handleReportModal = async (e, apiEndPoint = "", tab = "") => {
    let payload;

    if (siteId) {
      payload = {
        account_id: accountId,
        site_id: siteId,
        tab: tab,
      };
    } else {
      payload = {
        account_id: accountId,
        tab: tab,
      };
    }

    const response = await CallPOSTAPI(apiEndPoint, payload);
    if (response?.data?.status) {
      const fileUrl = response?.data?.data;

      if (fileUrl) {
        const downloadLink = document.createElement("a");

        downloadLink.href = `${BASE}/${fileUrl}`;
        downloadLink.download = fileUrl.split("/").pop();
        downloadLink.style.display = "none";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      } else {
        toast.error("No file available for download.");
      }
    }
  };

  const handleReportServiceCheckModal = (
    e,
    ApiEndPoint = "",
    selected_tab = ""
  ) => {
    setReportModal(true);
    setApiEndPoint(ApiEndPoint);
    setTab(selected_tab);
  };

  return (
    <>
      <Dropdown>
        {exportReport && (
          <Dropdown.Toggle
            className="tab-btn btn btn-transparent text-primary ms-2 bg-white DropDownBtn"
            id={"new-tab-btn"}
            style={{
              backgroundColor: "transparent !important",
              border: "none",
            }}
          >
            <img
              src={ExportReport}
              alt="export_report"
              height={25}
              width={25}
            />
            <span className="ms-1 textSize">Export Report</span>
          </Dropdown.Toggle>
        )}

        <Dropdown.Menu className="" style={{ minWidth: "20px" }}>
          {count && count?.aedCount > 0 ? (
            <>
              <Dropdown.Item
                className="DropDownListtextSize"
                onClick={(e) =>
                  handleReportModal(e, "aed/aed-tab-report", "basic")
                }
              >
                Basic AED
              </Dropdown.Item>

              {user.user_type === 3 ? (
                ""
              ) : (
                <Dropdown.Item
                  className="DropDownListtextSize"
                  onClick={(e) =>
                    handleReportModal(e, "aed/aed-tab-report", "master")
                  }
                >
                  Master AED
                </Dropdown.Item>
              )}
            </>
          ) : (
            ""
          )}

          {count && count?.accessoryCount > 0 ? (
            <>
              <Dropdown.Item
                className="DropDownListtextSize"
                onClick={(e) =>
                  handleReportModal(e, "aed/aed-tab-report", "acc")
                }
              >
                Accessories
              </Dropdown.Item>
            </>
          ) : (
            ""
          )}

          {user.user_type === 3 ? (
            ""
          ) : (
            <>
              {count && count?.servicesCount > 0 ? (
                <Dropdown.Item
                  className="DropDownListtextSize"
                  onClick={(e) =>
                    handleReportServiceCheckModal(
                      e,
                      "aed/aed-tab-report",
                      "services"
                    )
                  }
                >
                  AED Servicing Report
                </Dropdown.Item>
              ) : (
                ""
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>

      {/* Modal for service check report*/}
      <RangeDateReportModal
        reportModal={reportModal}
        setReportModal={setReportModal}
        apiEndPoint={apiEndPoint}
        tab={tab}
        accountId={accountId}
        siteId={siteId}
      />
    </>
  );
};

export default AedReportButton;
