import React, { useEffect, useState } from "react";
import {
  Form,
  Button as BButton,
  Button as BsButton,
  InputGroup,
} from "react-bootstrap";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services";
import { userContactList } from "../../../common/helper/BasicFn";
import { sortArrAscending } from "../../../common/helper/constants";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";
import UpdateRoleToUserModal from "../component/UpdateRoleToUserModal";
import EditAssignUserRoleModal from "../Model/EditAssignUserRoleModal";

const RoleToUser = () => {
  const [loading, setLoading] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [contactList, setContactList] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [rowData, setRowData] = useState("");
  const [tableReset, setTablReset] = useState(false);
  const [selectAccountValidations, setSelectAccountValidations] =
    useState(false);
  const [selectContactValidations, setSelectContactValidations] =
    useState(false);
  const [selectRoleValidations, setSelectRoleValidations] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [updateModal, setUpdateModal] = useState(false);
  const [contactID, setContactID] = useState("");
  const [sortedContactList, setSortedContactList] = useState([]);
  const [filteredSites, setFilteredSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [sortedNewRoleDataArr, setSortedNewRoleDataArr] = useState([]);
  const [allSites, setAllSites] = useState([]);
  const [formData, setFormData] = useState({
    account_id: "",
    contact_id: "",
    role_id: [],
    role_type: "",
    // select_site: "",
    site_ids: [],
  });
  const [isInvalid, setIsInvalid] = useState({
    account_id: false,
    contact_id: false,
    role_id: false,
    role_type: false,
    site_id: false,
  });

  const fetchLoad = async () => {
    const res = await CallGETAPI("permission/get-role");
    const filteredData =
      res?.data?.data?.role?.filter(
        (item) => Number(item?.is_admin_role) === 0
      ) || [];
    setRoleData(filteredData);
  };

  useEffect(() => {
    fetchLoad();
  }, []);

  const fetchContactData = async (selectedAccountId) => {
    setSortedContactList([]);
    // const res = await userContactList(selectedAccountId);
    const res = await CallGETAPI(
      `permission/get-users-withNoRoles/${selectedAccountId}?page=1&pageSize=50`
    );

    if (res) {
      let sortedArr = sortArrAscending(
        res?.data?.data?.finaldata,
        "contact_name"
      );
      setSortedContactList(sortedArr);
      setContactList(res);
    }
  };

  useEffect(() => {
    if (selectedAccountId !== null) {
      fetchContactData(selectedAccountId);
    }
  }, [selectedAccountId]);

  const handleChange = (e, key) => {
    const { value } = e.target;
    if (key === "account_id") {
      setSelectedAccountId(value);
    }

    // if(key === "role_type") {
    //   // setSortedNewRoleDataArr(pre)
    //   // .filter((item) => item.role_type === formData.role_type)
    // }

    setFormData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const HandleAssign = async (data) => {
    setContactID(data.contact_id);
    setUpdateModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let is_false = 0;
    let obj = {
      account_id: false,
      contact_id: false,
      role_id: false,
      role_type: false,
      site_id: false,
    };

    if (formData.account_id === "") {
      obj.account_id = true;
      is_false = 1;
    }

    if (formData.contact_id === "") {
      obj.contact_id = true;
      is_false = 1;
    }

    if (formData.role_id.length === 0) {
      obj.role_id = true;
      is_false = 1;
    }

    if (formData.role_type === "site_level" && formData.site_ids.length === 0) {
      obj.site_id = true;
      is_false = 1;
    }

    if (formData.role_type === "") {
      obj.role_type = true;
      is_false = 1;
    }
    if (is_false) {
      setIsInvalid(obj);
      return "";
    }

    setLoading(true);
    let rolesArr = formData.role_id;
    let siteIdArr = formData.site_ids;
    let finalRolesArr = rolesArr.map((role) => role.value);
    let finalsitesArr = siteIdArr.map((role) => role.value);
    let body = {
      ...formData,
      // site_id:
      //   selectedSites.length > 0
      //     ? selectedSites.map((item) => item.value).toString()
      //     : "",
      site_id: finalsitesArr + "",
      role_id: finalRolesArr + "",
    };

    const res = await CallPOSTAPI("permission/assign-permission-user", body);
    fetchCreateTblData();
    if (res?.data?.status) {
      setLoading(false);
      toast.success(res?.data?.msg);
      setFormData({ account_id: "", contact_id: "", role_id: [] });
      setFormKey((prevKey) => prevKey + 1);
      setSelectedSites([]);
    } else {
      setLoading(false);
      toast.error(res?.data?.message);
      setFormData({
        account_id: "",
        contact_id: "",
        role_id: [],
        role_type: "",
      });
    }
  };

  const handleRoles = () => {
    if (formData.role_id.length > 0) {
      setFormData({
        ...formData,
        role_id: [],
      });
    }

    if (roleData) {
      const nrda = roleData
        .filter((item) => item.role_type === formData.role_type)
        .map((obj) => ({
          label: obj.role_name,
          value: obj.role_id,
        }));

      let sortedArr = sortArrAscending(nrda, "label");
      setSortedNewRoleDataArr(sortedArr);
    }
  };

  useEffect(() => {
    handleRoles();
  }, [formData.role_type]);

  const handleRoleChange = (e) => {
    setFormData({
      ...formData,
      role_id: e,
    });
    setIsInvalid({ ...isInvalid, role_id: false });
    setSelectRoleValidations(false);
  };

  const renderSelectedTitleNames = () => {
    return [formData.role_id.map((item) => item.label).join(", ")];
  };

  const renderSelectTitle = () => {
    return (
      <div>
        {formData.role_id.length === 0
          ? "Select"
          : formData.role_id.length >= 3
          ? `${formData.role_id.length} Selected`
          : renderSelectedTitleNames()}
      </div>
    );
  };

  const renderSelectedSiteNames = () => {
    return [formData.site_ids.map((item) => item.label).join(", ")];
  };

  const renderSelectSiteTitle = () => {
    return (
      <div>
        {formData.site_ids.length === 0
          ? "Select"
          : formData.site_ids.length >= 3
          ? `${formData.site_ids.length} Selected`
          : renderSelectedSiteNames()}
      </div>
    );
  };

  const handleSiteChange = (e) => {
    setFormData({
      ...formData,
      site_ids: e,
    });
  };

  const renderRoleType = (data) => {
    return (
      <div>
        {data.role_type === "account_level"
          ? "Account Level"
          : data.role_type === "site_level"
          ? "Site Level"
          : data.role_type === "enhancement"
          ? "Enhancement"
          : ""}
      </div>
    );
  };

  function getCommonItems(array1, array2, key) {
    return array1?.filter((item1) =>
      array2?.length > 0
        ? array2.some(
            (item2) =>
              item1["account_site_info_id"] !== item2["account_site_info_id"]
          )
        : item1
    );
  }

  const getAllSitesByAccountId = async (id) => {
    try {
      const response = await CallGETAPI(`site/account-site-list/${id}`);
      if (response.data.status) {
        setAllSites(response.data.data.site_details);
      }
    } catch (error) {
      console.log({ error: error });
    }
  };

  useEffect(() => {
    if (formData.account_id) {
      getAllSitesByAccountId(formData.account_id);
    }
  }, [formData.account_id]);

  const getAllSitesByContactId = async (id) => {
    try {
      const response = await CallGETAPI(
        `permission/account-site-list-by-contact/${id}?search=&page=${1}&pageSize=${50}`
      );
      if (response.data.status) {
        let current_sites =
          response.data.data !== "" ? response.data.data.site_details : [];
        const commonItems = getCommonItems(allSites, current_sites, "id");
        let all_data = commonItems
          ? commonItems.map((item) => {
              return {
                label: item.account_site_name,
                value: item.account_site_info_id,
                // disabled: true,
              };
            })
          : [];
        setFilteredSites(all_data);
      }
    } catch (error) {
      // throw error;
      console.log({ error: error });
    }
  };

  useEffect(() => {
    if (formData.contact_id) {
      getAllSitesByContactId(formData.contact_id);
    }
  }, [formData.contact_id]);

  const headerColumns = [
    {
      Header: "Role Type",
      accessor: "role_type", // Maps to "role_type" field in the data
      // Cell: ({ row }) => renderRoleType(row.original), // Custom render function for the Role Type

      Cell: ({ row }) => {
        return (
          <div>
            {/* {row.original.roles.map((role, index) => (
              <div key={index}>
                {role.role_type.trim().replace("_", " ")}{" "}
              </div>
            ))} */}

            {row.original.roles[0].role_type.trim().replace("_", " ")}
          </div>
        );
      },
    },
    {
      Header: "Account Name",
      accessor: "account_name", // Maps to "account_name" field
    },
    {
      Header: "Contact Name",
      accessor: "contactName", // Maps to "contactName" field
    },
    {
      Header: "Role",
      accessor: "assigned_role", // Maps to "assigned_role" field
      width: 350, // Sets the column width
      sortType: "basic", // Enables sorting for this column
      Cell: ({ row }) => {
        // const role = row.original.assigned_role.split(",");
        return (
          <div>
            {row.original.roles.map((role, index) => (
              <div key={index}>{role.role_name.trim()}</div>
            ))}
          </div>
        );
      },
    },
    {
      Header: "Assigned Sites",
      // accessor: "", // Maps to "assigned_site" field
      width: 350, // Sets the column width
      // Cell: ({ row }) =>
      //   row.original.assigned_site !== "" ? row.original.assigned_site : "N/A", // Custom render logic for empty values

      Cell: ({ row }) => {
        // const assignedSite = row.original.assigned_site.split(",");
        return (
          <div>
            {row.original.sites.map((site, index) => (
              <div key={index}>
                {row.original?.assigned_site != ""
                  ? site.site_name.trim()
                  : "N/A"}
              </div>
            ))}
          </div>
        );
      },
    },
    {
      Header: "Assign Date",
      accessor: "modify_date", // Maps to "modify_date" field
      sortType: "basic", // Enables sorting for this column
      Cell: ({ row }) =>
        moment(row.original.modify_date).isValid()
          ? moment(row.original.modify_date).format("MM/DD/YYYY h:mm:ss")
          : "", // Formats the date using moment.js
    },
    {
      Header: "Actions",
      accessor: "actions", // Custom actions, not mapped to a specific field
      width: 170, // Sets the column width
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "5px",
          }}
        >
          <button
            type="button"
            style={{
              width: "80px",
              height: "30px",
              color: "white",
              background: "#d32f2f",
              border: "none",
              borderRadius: "10px",
            }}
            onClick={() => HandleAssign(row.original)}
          >
            Unassign
          </button>

          <button
            className="text-primary"
            type="button"
            onClick={() => {
              setEdit(true);
              setRowData(row.original);
            }}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              border: "none",
              background: "transparent",
            }}
          >
            <img
              src="/assets/images/edit.svg"
              alt="svg"
              style={{ marginRight: "0px" }}
            />
            <span className="ms-2">Edit</span>
          </button>
        </div>
      ),
    },
  ];

  const fetchCreateTblData = async () => {
    setTablReset(true);
    setTimeout(() => {
      setTablReset(false);
    }, 1000);
  };

  return (
    <>
      <div
        className="mt-4"
        style={{
          position: "relative",
          width: "100%",
          paddingInline: "0px",
          marginBottom: "35px",
        }}
      >
        <Box className="text-left pt-3 pb-1">
          <h4 className="heading">Assign Role to User</h4>
        </Box>

        <Form
          key={formKey}
          className=""
          onSubmit={handleSubmit}
          noValidate
          // validated={validated}
          id="create-new-equipment-form"
        >
          <div
            className="my-4"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "5%",
              marginBottom: "50px",
              justifyContent: "space-between",
            }}
          >
            <Form.Group className={"col"} style={{ width: 200 }}>
              <Form.Label>Select Role Type*</Form.Label>
              <select
                className="form-control"
                value={formData?.role_type}
                name="role_type"
                placeholder="Enter Role Type"
                onChange={(e) => {
                  handleChange(e, "role_type");
                  setIsInvalid({ ...isInvalid, role_type: false });
                  // handleRoleArray(e);
                }}
              >
                <option value="">--Select One--</option>
                <option value="account_level">Account Level</option>
                <option value="site_level">Site Level</option>
                <option value="enhancement">Enhancement</option>
              </select>
              {isInvalid?.role_type && (
                <Form.Control.Feedback type="" className="text-danger mt-1">
                  Role type is required.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className={"col"}>
              <Form.Label>Select Role*</Form.Label>
              <MultiSelect
                valueRenderer={renderSelectTitle}
                options={sortedNewRoleDataArr}
                value={formData.role_id}
                onChange={(e) => handleRoleChange(e)}
                labelledBy="Select"
                hasSelectAll={false}
              />

              {isInvalid.role_id && (
                <Form.Control.Feedback type="" className="text-danger mt-1">
                  Please Select Role.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className={"col"} style={{ maxWidth: "300px" }}>
              <Form.Label>Select Account*</Form.Label>
              <PaginateAutoComplete
                dropDownName="account_id"
                apiEndpoint={"permission/account-list-user"}
                idKey={"account_id"}
                valueKey={"account_name"}
                parentKey={"allAccount"}
                onSelect={(e) => {
                  handleChange(e, "account_id");
                  setIsInvalid({ ...isInvalid, account_id: false });
                  setSelectAccountValidations(false);
                }}
                shouldFetchOnOpen={false}
                isCache={true}
                selectDisabled={false}
                isSearchable={true}
                isAsync={true}
                pageSize={20}
              />

              {isInvalid.account_id && (
                <Form.Control.Feedback type="" className="text-danger mt-1">
                  Please Select Account.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className={"col"} style={{ maxWidth: "300px" }}>
              <Form.Label>Select Contact*</Form.Label>
              <select
                className="form-control"
                value={formData.contact_id}
                name="contact_id"
                placeholder="Enter Role Name"
                onChange={(e) => {
                  handleChange(e, "contact_id");
                  setIsInvalid({ ...isInvalid, contact_id: false });
                  setSelectContactValidations(false);
                }}
                required
              >
                <option value="">--Select One--</option>
                {sortedContactList?.length > 0 &&
                  sortedContactList.map((item, index) => (
                    <option value={item?.contact_id} key={index}>
                      {item?.contact_name}
                    </option>
                  ))}
              </select>

              {isInvalid.contact_id && (
                <Form.Control.Feedback type="" className="text-danger mt-1">
                  Please Select Contact.
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {formData.role_type === "site_level" && (
              <Form.Group className={"col"}>
                <Form.Label>Select Site</Form.Label>
                <MultiSelect
                  valueRenderer={renderSelectSiteTitle}
                  options={filteredSites ? filteredSites : []}
                  value={formData.site_ids}
                  onChange={(e) => handleSiteChange(e)}
                  labelledBy="Select"
                  hasSelectAll={false}
                />
                {isInvalid.site_ids && (
                  <Form.Control.Feedback type="" className="text-danger mt-1">
                    Please Select Sites.
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}

            <button
              className="btn btn-success text-uppercase ms-2"
              type="submit"
              style={{ marginTop: 25, height: 40 }}
              disabled={loading}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        </Form>

        <Box className="py-4">
          <NewGlobalTable
            method="GET"
            isSearchable={true}
            isCache={true}
            pageSize={50}
            apiEndPoint={"permission/fetch-user-role"}
            keyAPiName="mappedContactsUpdated"
            headerColumns={headerColumns}
            isTblUpdated={tableReset}
          />
        </Box>

        <UpdateRoleToUserModal
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
          contactID={contactID}
          fetchCreateTblData={fetchCreateTblData}
        />

        {edit && (
          <EditAssignUserRoleModal
            show={edit}
            setShow={setEdit}
            roleData={roleData}
            rowData={rowData}
            allSites={allSites}
            fetchCreateTblData={fetchCreateTblData}
          />
        )}
      </div>
    </>
  );
};

export default RoleToUser;
