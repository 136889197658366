
// src/App.js
import "./common/css/table.css";

import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { fetchPermissions } from './store/slices/permissionsSlice';
import PublicRoutes from './routes/PublicRoutes';
import UnauthorizedPage from './domains/auth/pages/UnauthorizedPage';
import ProtectedRoute from './routes/ProtectedRoute';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import NotFound404 from "./NotFound404";
const queryClient = new QueryClient()

const App = () => {
  const dispatch = useDispatch();
  const authStatus = true;// useSelector(selectAuthStatus);

  useEffect(() => {
    if (authStatus === 'authenticated') {
      dispatch(fetchPermissions());
    }
  }, [authStatus, dispatch]);

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          {/* Public Routes */}
          {PublicRoutes.map((item, i) => (
            <Route key={i} exact path={item.path} element={item.component} />
          ))}
          {ProtectedRoute.map((item, i) => (
            <Route key={i} exact path={item.path} element={item.component} />
          ))}
          <Route path="/unauthorized" element={<UnauthorizedPage />} />
          {/* <Route path="*" element={<Navigate to="/login" replace />} /> */}
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
};

export default App;