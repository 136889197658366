import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
// import "./table.css";

import New from "../../../common/img/New.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { DecryptToken, getPermission } from "../../../common/helper";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import { addNewBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
import { isPermission } from "../../../common/helper/PermissionManager";
export default function Documents({ props, document_api, fetch_key, accountId, url, stateData }) {


  const navigate = useNavigate();
  // const [loading, setLoading] = useState(true);
  const user = DecryptToken();
  const privilege = getPermission();
  const dispatch = useDispatch()
  const getDateTime = (e) => {
    if (e.updatedAt) {
      return moment(e.updatedAt).format("MM/DD/YYYY hh:mm A")
    } else {
      return moment(e.createdAt).format("MM/DD/YYYY hh:mm A")

    }
  };
  const BreadCrumbsObject = {
    title: "",
    type: "",
    tab: "",
  };
  const handleDispatched = (id, name) => {

    BreadCrumbsObject.title = name + " Details ";
    dispatch(addNewBreadcrumbs({ ...BreadCrumbsObject, url: `/account-document-details/${id}` }));
  };

  // const handleNewDispatched = () => {
  //   BreadCrumbsObject.title = " New Document Form ";
  //   dispatch(addNewBreadcrumbs({ ...BreadCrumbsObject, url: "/account-document-upload" }));
  // };

  const renderLink = (e) => {
    const id = e?.document_id;
    const hasPermission = isPermission({ type: "link", name: "documentDetails" }) === 1;


    return (
      <>
        {hasPermission ? (
          <span
            onClick={() => {

              handleDispatched(id, e?.parent_document_name);
              navigate(`/account-document-details/${id}`);

            }}
            className={"link"}
          >
            {e?.parent_document_name}
          </span>
        ) : (
          <span>{e?.parent_document_name}</span>
        )}
      </>
    );
  };

  const redirectUrl = '';

  // const fetchData = async () => {

  //   setLoading(false);

  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);
  const headerColumns = [
    {
      Header: "Document Name",
      accessor: "parent_document_name",
      width: "20%",
      Cell: ({ row }) => {
        return renderLink(row.original); // Assumes renderLink function is defined
      },
      disableSortBy: true,
    },
    {
      Header: "Comment",
      accessor: "comment",
      width: "20%",
      disableSortBy: true,
    },
    {
      Header: "Related To",
      accessor: "related_to",
      width: "20%",
      disableSortBy: true,
    },
    {
      Header: "Uploaded By",
      accessor: "uploaded_by_name",
      width: "20%",
      disableSortBy: true,
    },
    {
      Header: "Uploaded Date",
      accessor: "createdAt",
      width: "20%",
      Cell: ({ row }) => (
        <span>
          {getDateTime(row.original)}{" "}
        </span>
      ),
      disableSortBy: true,
    },
  ];
  return (
    <div className="relative" style={{
      width: "100%",
      paddingInline: "0px",
      marginBottom: "400px",
    }}>
      <>
        <Box className="text-left pt-3 pb-1 d-flex">
          <h3 className="heading">Documents Information</h3>
          {Number(isPermission({ type: "btn", name: "NewDocument" })) === 1 ? (
            <span className='' style={{ marginLeft: 'auto' }}>
              <button type="button"
                onClick={() => {
                  // handleNewDispatched()
                  navigate("/account-document-upload", {
                    state: {
                      type: "Account",
                      accountId: accountId,
                      siteId: "",
                      url: url,
                      stateData: stateData
                    },
                  });
                }}

                className="btn ">
                <img
                  src={New}
                  alt="New"
                  style={{ marginRight: "5px" }}
                />
                <span style={{ color: '#0C71C3' }} > New</span>
              </button>
            </span>
          ) : ""}
        </Box>



        <div className="data-table pb-3">
          <NewGlobalTable
            method="GET"
            isSearchable={false}
            isCache={true}
            pageSize={20}
            // customRenderData={handleSorting}
            apiEndPoint={document_api}
            keyAPiName={"documentList"}
            headerColumns={headerColumns}
          />
        </div>
      </>


    </div>
  );
}
