import React, { useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";

const AccountsCard = ({ sections, setSections }) => {
  const [accounts, setAccounts] = useState({});
  const navigate = useNavigate();
  const id = "accounts";

  const navigateAccount = () => {
    if (Number(accounts?.totalcount) > 1) {
      Number(isPermission({ type: "dashboard", name: "AccountListing" })) ===
        1 && navigate("/accounts-listing");
    } else {
      Number(isPermission({ type: "dashboard", name: "AccountDetails" })) ===
        1 && navigate(`/account-details/${accounts?.account_id}/Details`);
    }
  };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setAccounts(matched);
  }, [sections]);

  return (
    <>
      <div className="p-4 rounded-lg">
        <h3
          className="text-center text-lg font-bold mb-2"
          style={{ cursor: Number(accounts?.totalcount) > 0 ? "pointer" : "" }}
          onClick={() => Number(accounts?.totalcount) > 0 && navigateAccount()}
        >
          {accounts?.totalcount}
        </h3>
        <p className="text-center"> Accounts</p>
      </div>
    </>
  );
};

export default AccountsCard;
