// ----- New Contact-Tab Listing ---- //
import React, { useState, useEffect } from "react";
import {
  DataSymbol,
  DollarsIcon,
  RoundCheck,
  TruckSymbol,
} from "../../../../common/helper/icons.js";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from "react-table";

import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import { DecryptToken, getPermission } from "../../../../common/helper";
import { Skeleton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import New from "../../../../common/img/New.png";
import { CallGETAPI } from "../../../../common/services/index.js";
import NewGlobalTable from "../../../../common/components/custom-ui/NewGlobalTable.jsx";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice.js";
import { AccountPopTab } from "../../../../common/hooks/fieldsConfig.js";
import { handleTabfunction } from "../../../../store/slices/TabSlice.js";
import { isPermission } from "../../../../common/helper/PermissionManager.jsx";
// import { user } from "../../../../common/helper/BasicFn.js";

const PopList = ({
  accountId,
  siteId = 0,
  is_user,
  contact_id = 0,
  setTabTbldata,
  accountID = 0,
  type = "ACCOUNT",
}) => {
  let redirectUrl = `/account/new-pop/` + accountId;
  const navigate = useNavigate();
  const filterData = useSelector((state) => state.accountlistfilter.filterData);
  const [showLoading, setShowLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [popList, setPopList] = useState([]);
  const user = DecryptToken();
  // setIsDataFetched(true);

  const [loading, setLoading] = useState(true);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const dispatch = useDispatch();
  const subAdminPermission = [
    "site-details",
    "contact-tab",
    "equipment-tab",
    "training-tab",
    "inperson-tab",
    "notes-tab",
    "documents-tab",
  ];

  // fetch on load

  const fetchOnlaod = async () => {
    setShowLoading(true);
    if (is_user) {
      let contactData = await CallGETAPI(
        `account/site-contacts-list/${siteId}?page=${currentPage}`
      );

      setPopList(contactData?.data?.data?.contact_list || []);
    } else {
      let ContactData = await CallGETAPI(
        `site/site-contacts-list/${siteId}?page=${currentPage}`
      );
      if (ContactData) {
        setPopList(ContactData?.data?.data || []);
      }
    }
    setShowLoading(false);
  };

  const BreadCrumbsObject = {
    title: "",
    type: "",
    tab: "",
  };

  const handleDispatch = (pop_id, accountId, name) => {
    BreadCrumbsObject.title = name + " Details";
    BreadCrumbsObject.type = "pop";
    BreadCrumbsObject.tab = "Details";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/pop/get-pop-performance/${pop_id}/${accountId}/Details`,
      })
    );
  };
  const handleContactDispatch = (accountId, contact_id, name) => {
    BreadCrumbsObject.type = "pop";
    BreadCrumbsObject.tab = "Details";
    BreadCrumbsObject.title = name + " Details";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/${accountId}/contact-details/${contact_id}/Details`,
      })
    );
  };
  const handledDispatch = (accountId) => {
    BreadCrumbsObject.type = "pop";
    BreadCrumbsObject.tab = "Details";
    BreadCrumbsObject.title = "New Pop Form";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: "/account/new-pop/" + accountId,
      })
    );
  };
  // // on load
  // useEffect(() => {
  //     fetchOnlaod();
  // }, [currentPage]);

  const fetchData = async () => {
    setLoading(true);
    const res = await CallGETAPI("pop/pop-list-by-account/" + accountId);
    if (res.data.status) {
      const resultData = res?.data?.data || [];
      setPopList(resultData);
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    const fetchDataAndSetLoading = async () => {
      try {
        // await Promise.all(fetchData()); // Ensure this returns an array of promises
        await fetchData(); // Ensure this returns an array of promises
      } catch (error) {
        console.error("Error fetching data:", error); // Handle errors if any promises fail
      } finally {
        setShowLoading(false); // Set loading state to false when done
      }
    };

    fetchDataAndSetLoading();
  }, []);
  // useEffect(() => {
  //     if (popList?.length > 0) {
  //         setTabTbldata((prev) => ({
  //             ...prev,
  //             contact: true,
  //         }));
  //     }
  // }, [popList]);
  const getColumnWidth = (header) => {
    switch (header) {
      case "Coverage":
        return "220px"; // Increase width for these headers
      case "Contract Year":
        return "150px"; // Increase width for these headers
      case "Status":
        return "125px";
      case "QB Invoice":
        return "150px"; // Decrease width for these headers
      case "Contact":
        return "220px"; // Maintain default width for the Contact column
      default:
        return "auto"; // Default width for other columns
    }
  };

  const RenderPopType = (data) => {
    const rowData = data.data;
    return (
      <>
        <span
          className={
            Number(isPermission({ type: "link", name: "popType" }) === 1)
              ? "link"
              : ""
          }
          onClick={() => {
            if (Number(isPermission({ type: "link", name: "popType" }) === 1)) {
              dispatch(
                handleTabfunction({ value: AccountPopTab.Details, type: "aed" })
              );
              handleDispatch(data.pop_id, accountId, data?.pop_type);
              navigate(
                `/pop/get-pop-performance/${data.pop_id}/${accountId}/Details`,
                {
                  state: {
                    // siteTitle: "pop : " + data?.pop_name,
                    editUrl: `/account/pop-details-edit/${data.pop_id}`,
                    deleteUrl: `/account/pop-details-edit/${data.pop_id}`,
                    type: "pops",
                  },
                }
              );
            }
          }}
        >
          {data?.pop_type}
        </span>
      </>
    );
  };

  const RenderContractYear = (data) => {
    return <>{data.contract_year}</>;
  };

  const RenderCoverage = (data) => {
    const addOneYear = (dateString) => {
      const date = new Date(dateString);

      // Check if the date is valid
      if (isNaN(date.getTime())) {
        return "Invalid Date"; // Return an error message or handle it as needed
      }

      // Add one year to the date
      date.setFullYear(date.getFullYear() + 1);

      // Format the date as 'dd/mm/yyyy' (adjust format as needed)
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();

      return `${month}/${day}/${year}`;
    };

    // Extract and format the original and new dates
    const originalDate = data.contract_start;
    const newDate = addOneYear(originalDate);

    return <>{` ${originalDate} - ${newDate}`}</>;
  };
  const RenderContact = (data) => {
    // Log the raw data for debugging

    // Check if data?.contact is a string that needs parsing
    let dataa;
    try {
      dataa =
        typeof data?.contact === "string"
          ? JSON.parse(data.contact)
          : data.contact;
    } catch (error) {
      console.error("Failed to parse contact data", error);
      return null;
    }

    // Log the parsed data for debugging

    // Ensure contract_officer exists and is an array
    const contacts = Array.isArray(dataa?.contract_officer)
      ? dataa.contract_officer
      : [];

    // Log contacts for debugging

    // Handle case where contacts might still be undefined or not an array
    if (!Array.isArray(contacts)) {
      return <div>No contacts available</div>;
    }

    // Generate contact names and ids
    const contactElements = contacts?.map((contact) => (
      <div
        key={contact.contact_id}
        className={
          Number(isPermission({ type: "link", name: "contactPopTbl" })) === 1
            ? "link"
            : ""
        }
        style={{
          cursor:
            Number(isPermission({ type: "link", name: "contactPopTbl" })) === 1
              ? "pointer"
              : "default",
        }}
        onClick={() => {
          if (
            Number(isPermission({ type: "link", name: "contactPopTbl" })) === 1
          ) {
            handleContactDispatch(
              accountId,
              contact.contact_id,
              contact.contact_name
            );
            navigate(
              `/account/${accountId}/contact-details/${contact.contact_id}/Details`,
              {
                state: {
                  // siteTitle: "Contact : " + contact.contact_name,
                  editUrl: `/account/contact-details-edit/${contact.contact_id}`,
                  deleteUrl: `/account/contact-details-edit/${contact.contact_id}`,
                  type: "Contacts",
                },
              }
            );
          }
        }}
      >
        {contact.contact_name}
      </div>
    ));

    return <div>{contactElements || "NA"}</div>;
  };

  const RenderStatus = (status) => {
    if (parseInt(status) === 1) {
      return "Active";
    }

    if (parseInt(status) === 0) {
      return "Inactive";
    }

    if (parseInt(status) === 2) {
      return "Cancelled";
    }

    return "N/A";
  };

  const RenderQbInvoice = (data) => {
    return (
      <>
        <span className="text-primary">
          {data?.invoice_paid ? <DollarsIcon /> : ""} &nbsp;
        </span>
        {data.qb_invoice}
      </>
    );
  };
  const RenderContractNumber = (data) => {
    return (
      <>
        {data.contract}

        {data?.req && <p className="m-0">Req: {data.req}</p>}
        {data?.order && <p className="m-0 ">Order: {data?.order}</p>}
        {data?.modification && (
          <p className="m-0 ">Mod: {data?.modification}</p>
        )}
      </>
    );
  };
  // const handlePageChange = (newPage) => {
  //     setCurrentPage(newPage);
  // };

  const headerColumns = [
    {
      Header: "POP Type",
      accessor: "Pop_type",
      Cell: ({ row }) => (
        <div style={{ cursor: "default" }}>
          {parseInt(row.original.active) === 1 && (
            <span style={{ width: "10px" }}>
              <RoundCheck />
            </span>
          )}
          &nbsp; {RenderPopType(row.original)}
        </div>
      ),
      width: "15%",
    },
    {
      Header: "Contract Year",
      accessor: "contract_year",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div style={{ cursor: "default" }}>
              {RenderContractYear(row.original)}
            </div>
          )}
        </>
      ),
      width: "12%",
      disableSortBy: false,
    },
    {
      Header: "Coverage",
      accessor: "Coverage",

      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div style={{ cursor: "default" }}>
              {RenderCoverage(row?.original)}
            </div>
          )}
        </>
      ),
      width: "15%",
      disableSortBy: false,
    },
    {
      Header: "Contact",
      accessor: "Contact",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div>{RenderContact(row.original)}</div>
          )}
        </>
      ),
      width: "15%",
    },
    {
      Header: "Contract#",
      accessor: "contract_number",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div style={{ cursor: "default" }}>
              {" "}
              {RenderContractNumber(row.original)}
            </div>
          )}
        </>
      ),
      width: "15%",
    },
    {
      Header: "QB Invoice",
      accessor: "qb_invoice",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div style={{ cursor: "default" }}>
              {" "}
              {RenderQbInvoice(row.original)}
            </div>
          )}
        </>
      ),
      width: "15%",
    },
    {
      Header: "Status",
      accessor: "status",
      width: "8%",
      Cell: ({ row }) => (
        <>
          {showLoading ? (
            <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
          ) : (
            <div style={{ cursor: "default" }}>
              {RenderStatus(row.original.status)}
            </div>
          )}
        </>
      ),
    },
  ];
  // const columns = React.useMemo(
  //     () => [
  //         {
  //             Header: "POP Type",
  //             accessor: "Pop_type",
  //             Cell: ({ row }) => (
  //                 <div>
  //                     {parseInt(row.original.active) === 1 && (
  //                         <span style={{ width: '10px' }}>
  //                             <RoundCheck />
  //                         </span>
  //                     )}
  //                     &nbsp; {RenderPopType(row.original)}
  //                 </div>
  //             ),
  //             width: "20%",
  //         },
  //         {
  //             Header: "Contract Year",
  //             accessor: "contract_year",
  //             Cell: ({ row }) => (
  //                 <>
  //                     {showLoading ? (
  //                         <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
  //                     ) : (
  //                         <div>{RenderContractYear(row.original)}</div>
  //                     )}
  //                 </>
  //             ),
  //             width: "10%",
  //             disableSortBy: false,
  //         },
  //         {
  //             Header: "Coverage",
  //             accessor: "Coverage",

  //             Cell: ({ row }) => (
  //                 <>
  //                     {showLoading ? (
  //                         <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
  //                     ) : (
  //                         <div>{RenderCoverage(row?.original)}</div>
  //                     )}
  //                 </>
  //             ),
  //             width: "10%",
  //             disableSortBy: false,
  //         },
  //         {
  //             Header: "Contact",
  //             accessor: "Contact",
  //             Cell: ({ row }) => (
  //                 <>
  //                     {showLoading ? (
  //                         <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
  //                     ) : (
  //                         <div>{RenderContact(row.original)}</div>
  //                     )}
  //                 </>
  //             ),
  //             width: "15%",
  //         },
  //         {
  //             Header: "Contract#",
  //             accessor: "contract_number",
  //             Cell: ({ row }) => (
  //                 <>
  //                     {showLoading ? (
  //                         <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
  //                     ) : (
  //                         <div> {RenderContractNumber(row.original)}</div>
  //                     )}
  //                 </>
  //             ),
  //             width: "15%",
  //         },
  //         {
  //             Header: "QB Invoice",
  //             accessor: "qb_invoice",
  //             Cell: ({ row }) => (
  //                 <>
  //                     {showLoading ? (
  //                         <Skeleton width={'90%'} height={20} style={{ margin: "10px" }} />
  //                     ) : (
  //                         <div> {RenderQbInvoice(row.original)}</div>
  //                     )}
  //                 </>
  //             ),
  //             width: "15%",
  //         },
  //         {
  //             Header: "Status",
  //             accessor: "status",
  //             Cell: ({ row }) => (
  //                 <>
  //                     {showLoading ? (
  //                         <Skeleton width={"90%"} height={20} style={{ margin: "10px" }} />
  //                     ) : (
  //                         <div>{RenderStatus(row.original.status)}</div>

  //                     )}
  //                 </>
  //             ),
  //             width: "15%",
  //         },
  //     ],
  //     [showLoading]
  // );

  // const {
  //     getTableProps,
  //     getTableBodyProps,
  //     headerGroups,
  //     prepareRow,
  //     page,
  //     canPreviousPage,
  //     canNextPage,
  //     pageOptions,
  //     pageCount,
  //     gotoPage,
  //     nextPage,
  //     previousPage,
  //     setPageSize,
  //     setGlobalFilter,
  //     state: { pageIndex, pageSize, globalFilter },
  // } = useTable(
  //     {
  //         columns,
  //         data: popList,
  //         initialState: { pageIndex: 0, pageSize: 20 },
  //     },
  //     useGlobalFilter,
  //     useSortBy,
  //     usePagination
  // );

  return (
    <>
      {/* <div className="bg-light base-table"> 
            
                <Link
                                style={{ textDecoration: "none" }}
                                onClick={handledDispatch(accountId)}
                                to={"/account/new-pop/" + accountId}
                            >
                                <img src={New} />
                                <span style={{ color: "#0C71C3" }}> New</span>
                            </Link>
            
            */}
      <Box className="d-flex justify-content-between align-items-center py-2">
        <h3 className="heading">Pop Information</h3>
        <div></div>
        {Number(isPermission({ type: "btn", name: "newPop" })) === 1 ? (
          <>
            <span
              style={{ textDecoration: "none", cursor: "pointer" }}
              onClick={() => {
                handledDispatch(accountId);
                navigate("/account/new-pop/" + accountId);
              }}
              // to={"/account/new-pop/" + accountId}
            >
              <img src={New} />
              <span style={{ color: "#0C71C3" }}> New</span>
            </span>
          </>
        ) : (
          ""
        )}
      </Box>

      {/* <div className="table-responsive">
                 
                    <table {...getTableProps()} className="table table-bordered table-hover">
                        <thead>
                            {headerGroups?.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers?.map((column) => (
                                        <td
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            style={{ width: getColumnWidth(column.Header), backgroundColor: "#999" }}
                                            className="tb-td"
                                        >
                                            <div className="d-flex align-items-center th-d">
                                                {column.render("Header")}

                                                <span className="ml-1">
                                                    {!column.disableSortBy &&
                                                        (column.isSorted ? (
                                                            column.isSortedDesc ? (
                                                                <SouthIcon size={2} />
                                                            ) : (
                                                                <NorthIcon size={2} />
                                                            )
                                                        ) : (
                                                            <SouthIcon size={2} />
                                                        ))}
                                                </span>
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </thead>

                        <tbody {...getTableBodyProps()} >
                            {loading ? (
                                [...Array(5)].map((_, i) => (
                                    <tr key={i}>
                                        {columns?.map((col, j) => (
                                            <td
                                                key={j}
                                                style={{
                                                    backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                                                }}
                                                className="tb-td"
                                            >
                                                <Skeleton
                                                    width={"90%"}
                                                    height={30}
                                                    style={{ margin: "10px" }}
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : ""}

                            {/*  Fetched Data And Showing the results */}
      {/* {(
                                page?.map((row, i) => { prepareRow(row);
                                    return (
                                        <tr {...row.getRowProps()} key={i}>
                                            {row.cells?.map((cell, j) => (
                                                <td
                                                    {...cell.getCellProps()}
                                                    key={j}
                                                    style={{
                                                        backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                                                    }}
                                                    className="tb-td"
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })
                            )}
                            {/* No Data Found Condition */}
      {/* {isDataFetched && popList?.length  === 0 ? 
                                <tr>
                                    <td
                                        colSpan={columns.length}
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            fontStyle: "italic",
                                            color: "grey",
                                        }}
                                    >
                                        No Data Found.
                                    </td>
                                </tr>
                                : ""}


                        </tbody>
                    </table>
                </div>
            </div> */}

      {/* {popList?.length > 0 && (
                <>
                    <div className="mt-4 d-flex justify-content-end align-items-center">
                        <div className="d-flex align-items-center ml-auto">
                            {Array.from({ length: totalPage }, (_, i) => i + 1)?.map(
                                (page) => (
                                    <button
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                        className={`btn btn-light mx-1 ${currentPage === page ? "active" : ""
                                            }`}
                                    >
                                        {page}
                                    </button>
                                )
                            )}
                        </div>
                    </div> */}
      {/* <hr style={{ border: "2px solid #0d6efd" }} /> */}
      {/* </>
            )} */}

      <NewGlobalTable
        method="GET"
        keyAPiName="popData"
        apiEndPoint={
          user?.user_type === 0
            ? `pop/pop-list-by-account/${accountId}`
            : `user/pop-list/${accountId}`
        }
        headerColumns={headerColumns}
      />
    </>
  );
};

export default PopList;
