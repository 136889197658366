import React, { useEffect, useState, useMemo, useRef } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  checkLabelsLength,
  fetchPieChartData,
  findSectionById,
} from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";

ChartJS.register(ArcElement, Tooltip, Legend);

const getRandomColor = () =>
  "#" + Math.floor(Math.random() * 16777215).toString(16);

const fixedColors = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4CAF50",
  "#FF9F40",
  "#9966FF",
  "#C9CBCF",
  "#FF6384",
  "#4BC0C0",
  "#FFCD56",
];

function AedOwnedPieChart({ sections, setSections }) {
  const [piedata, setPiedata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartWidth, setChartWidth] = useState(0);
  const chartContainerRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchPieChartData(
          sections,
          "aedsOwned",
          "aedsOwned"
        );
        setPiedata(result);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [sections]);

  useEffect(() => {
    if (!chartContainerRef.current) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { width } = entry.contentRect;
        setChartWidth(width);
      }
    });

    resizeObserver.observe(chartContainerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const navigateAedowned = () => {
    Number(isPermission({ type: "dashboard", name: "EquipmentListing" })) ===
      1 && navigate(`/account-details/${piedata[0]?.account_id}/Equipment`);
  };

  const { labels, dataValues, backgroundColor } = useMemo(() => {
    if (!piedata) return { labels: [], dataValues: [], backgroundColor: [] };

    const labels = piedata.map((item) => item.aed_brands_model_name);
    const dataValues = piedata.map((item) => item.aed_count);
    const randomColors = Array.from(
      { length: Math.max(0, labels.length - fixedColors.length) },
      getRandomColor
    );
    const backgroundColor = [...fixedColors, ...randomColors];

    return { labels, dataValues, backgroundColor };
  }, [piedata]);

  // const data = useMemo(
  //   () => ({
  //     labels,
  //     datasets: [
  //       {
  //         data: dataValues,
  //         backgroundColor,
  //         hoverBackgroundColor: backgroundColor,
  //       },
  //     ],
  //   }),
  //   [(labels, dataValues, backgroundColor)]
  // );

  // const { labels, dataValues, backgroundColor } = useMemo(() => {
  //   if (!piedata) return { labels: [], dataValues: [], backgroundColor: [] };

  //   const labels = piedata.map((item) => item.aed_brands_model_name);
  //   const dataValues = piedata.map((item) => item.aed_count);
  //   const randomColors = Array.from(
  //     { length: Math.max(0, labels.length - fixedColors.length) },
  //     getRandomColor
  //   );
  //   const backgroundColor = [...fixedColors, ...randomColors];

  //   return { labels, dataValues, backgroundColor };
  // }, [piedata]);

  const data = useMemo(() => {
    const chartData = {
      labels,
      datasets: [
        {
          data: dataValues,
          backgroundColor,
          hoverBackgroundColor: backgroundColor,
        },
      ],
    };

    return chartData;
  }, [labels, dataValues, backgroundColor, sections, setSections]); // Add proper dependencies

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
  };

  const getChartSize = () => {
    const maxSize = Math.min(chartWidth * 0.8, 400);
    return { width: maxSize, height: maxSize };
  };

  const chartSize = getChartSize();

  return (
    <div
      className="aed-owned-pie-chart"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "15px",
        boxSizing: "border-box",
        minHeight: "450px",
      }}
    >
      <h4 style={{ margin: "0 0 15px 0" }}>AED Owned</h4>
      <div
        className="chart-content"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "20px",
          flex: 1,
          minHeight: 0,
          alignItems: "center",
        }}
      >
        <div
          ref={chartContainerRef}
          className="chart-container"
          style={{
            flex: "1 1 auto",
            minWidth: "100px",
            maxWidth: "65%",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: chartSize.width,
              height: chartSize.height,
              minWidth: "100px",
              minHeight: "100px",
            }}
          >
            <Pie data={data} options={options} />
          </div>
        </div>
        <div
          className="label-list"
          style={{
            flex: "1 1 auto",
            minWidth: "150px",
            maxWidth: "30%",
            maxHeight: "400px",
            overflowY: "auto",
            padding: "10px",
          }}
        >
          {labels.map((label, index) => (
            <div
              key={index}
              className="label-item"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                margin: "4px 0",
                cursor: "pointer",
              }}
              onClick={navigateAedowned}
            >
              <div
                style={{
                  flexShrink: 0,
                  width: "12px",
                  height: "12px",
                  backgroundColor: backgroundColor[index],
                  borderRadius: "50%",
                }}
              />
              <span
                style={{
                  color: backgroundColor[index],
                  fontSize: "1rem",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {label}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AedOwnedPieChart;
