import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { CallGETAPI } from "../services";

const StateField = ({
  selectedCountry,
  setFormData,
  valueKey,
  validated,
  required,
  type,
  index,
  altTrainerForm,
  stateSelectedValue,
  resetState=false,
  // setResetState,
  setValueEmpty,
}) => {
  console.log({selectedCountry})
  console.log({valueKey})
  console.log({setValueEmpty})

  const [value, setValue] = useState("");
  const [valueValidated, setValueValidated] = useState(false);
  const [states, setStates] = useState([]);

  const [stateArray, setStateArray] = useState([]);

  const handleChange = (event) => {
    if (value?.length > 0 && event?.target?.value.length <= value?.length) {
      setValue("");
    } else {
      formDataValueUpdate(event?.target?.value);
      // console.log({resetState})
      // setResetState(false);
    }
  };

  // get state value
  const getState = (value) => {
    if (Number(selectedCountry) == 231) {
      return states.find((state) => state.abbreviation === value.toUpperCase());
    } else {
      return states.find(
        (state) => state.state_name.toLowerCase() === value.toLowerCase()
      );
    }
  };

  // set form data
  const formDataValueUpdate = (value) => {
    if (type != "array") {
      let state = getState(value);

      if (state) {
        setValue(state.state_name);
        setFormData((old) => ({ ...old, [valueKey]: state.state_id }));
        setFormData((old) => ({
          ...old,
          [valueKey + "_name"]: state.state_name,
        }));
        setFormData((old) => ({
          ...old,
          [valueKey + "_abbreviation"]: state.abbreviation,
        }));
      } else {
        setValue(value);
        setFormData((old) => ({ ...old, [valueKey]: 0 }));

        // setValueValidated(false);
      }
    } else {
      let state = getState(value);
      if (state) {
        setValue(state.state_name);
        let newArr = [...altTrainerForm];
        newArr[index][valueKey] = state.state_id;
        newArr[index][valueKey + "_name"] = state.state_name;
        newArr[index][valueKey + "_abbreviation"] = state.abbreviation;
        setFormData(newArr);
      } else {
        setValue(value);
        // setValueValidated(false);
      }
    }
  };

  // fetch country
  const fetchCountry = async (id) => {
    if(!id){
      console.log("Undefined Country ID:",id);
      return "";
    }
    const results = await CallGETAPI("account/get-state-by-country/" + id);
    if (results?.status) {
      return results?.data?.data?.state;
    }
  };

  // get countriesList on page load
  const fetchOnLoad = async () => {
    const states = await fetchCountry(selectedCountry);

    if (stateSelectedValue) {
      const findState = states?.find(
        (state) => state.state_id == parseInt(stateSelectedValue)
      );
      setFormData((old) => ({ ...old, [valueKey]: findState?.state_id }));
      setFormData((old) => ({
        ...old,
        [valueKey + "_name"]: resetState == "true" ? "" : findState?.state_name,
      }));
      setFormData((old) => ({
        ...old,
        [valueKey + "_abbreviation"]: resetState == "true" ? "" : findState?.abbreviation,
      }));
    }
    setStates(states);
  };

  // get selected country
  useEffect(() => {
    if (selectedCountry) {
      fetchOnLoad();
    }
  }, [selectedCountry]);

  // useEffect(() => {
  //   if (value?.trim()?.length == 0) {
  //     setValueValidated(true);
  //   } else {
  //     setValueValidated(false);
  //   }
  // }, [value]);

  useEffect(() => {
    if (value?.trim()?.length === 0 || resetState) {
      setValueValidated(true);
      setValue("");
    } else {
      setValueValidated(false);
    }
  }, [value, resetState]);

  // get state
  const filterState = async () => {
    const AllStates = await fetchCountry(selectedCountry);
    if(AllStates){
      const filteredStates = AllStates?.filter(
        (state) => state.state_id == parseInt(stateSelectedValue)
      );
      const state = filteredStates[0];
      setValue(state?.state_name);
    }
  };

  useEffect(() => {
    if (stateSelectedValue) {
      filterState();
    }
  }, [stateSelectedValue, selectedCountry]);

  // useEffect(() => {
  //   if (typeof setValueEmpty === 'function') {
  //     if (value?.trim()?.length === 0) {
  //       setValueEmpty(prev => ({
  //         ...prev,
  //         site_state: "",
  //       }));
  //     }
  //   } else {
  //     console.error("setValueEmpty is not a function");
  //   }
  // }, [value]);

  // useEffect(() => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     account_site_state_name: valueKey === "account_site_state" ? value : prev.account_site_state_name,
  //     account_billing_info_state_name: valueKey === "account_billing_info_state" ? value : prev.account_billing_info_state_name,
  //     account_shipping_info_state_name: valueKey === "account_shipping_info_state" ? value : prev.account_shipping_info_state_name,
  //   }));
  // }, [value, valueKey, setFormData]);
  
  
  // console.log("setValueEmpty:", setValueEmpty);
  console.log("setFormData", setFormData)


  return (
    <div>
      <Form.Group className={"col"}>
        <Form.Control
          name="state"
          type="text"
          // value={value}
          value={value === "" || value === undefined || resetState ? "" : value}
          onChange={handleChange}
          className={
            validated && required && valueValidated ? "invalid-input" : ""
          }
          // required
        />
        {/* {valueValidated && <p className="invalid">Please Enter Valid State.</p>} */}
        {validated && required && valueValidated && (
          <p className="invalid">Please Enter State.</p>
        )}
      </Form.Group>
    </div>
  );
};

export default StateField;