import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CallGETAPI, CallPOSTAPI } from '../../../../common/services/index';
import { toast } from 'react-toastify';

const SupportContactsModal = ({ addContactsModal, setAddContactsModal, issueType, supportId, onContactsSubmitted }) => {
  const handleClose = () => setAddContactsModal(false);
  const [contactsList, setContactsList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [reasonForReassign, setReasonForReassign] = useState('');
  const [loading, setLoading] = useState(false);
  const [DefaultContact, setDefaultContact] = useState()
  const fetchLoad = async () => {
    try {
      const response = await CallGETAPI('support/assign-by-issues/' + issueType);

      const contactsdetails = response?.data?.data?.contactList || [];
      const default_admin = response?.data?.data?.default_admin;
      console.log({ default_admin })
      setDefaultContact(default_admin)
      setContactsList(contactsdetails);
    } catch (error) {
      toast.error("Failed to load contacts");
    }
  };

  useEffect(() => {
    if (addContactsModal) {
      fetchLoad();
    }
  }, [addContactsModal]);

  const payload = {
    "assign_to": selectedValue,
    "description": reasonForReassign,
  };

  const handleSubmit = async () => {
    if (selectedValue && !reasonForReassign) {
      toast.error("Please fill in both fields.");
      return;
    }

    setLoading(true);
    try {
      const response = await CallPOSTAPI(`support/reassign-ticket/${supportId}`, payload);
      if (response) {
        toast.success("Reassigned successfully");
        handleClose();
        if (onContactsSubmitted) {
          await onContactsSubmitted();
        }
      }
    } catch (error) {
      toast.error("Failed to reassign ticket");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      show={addContactsModal}
      onHide={handleClose}
      dialogClassName="modal-30w"
      aria-labelledby="example-custom-modal-styling-title"
      size="lg"
      id="contacts-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Contacts Selection</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "300px" }}>
        <div className="modal-container">
          <div className="my-modal-section container-fluid">
            <h5 style={{ marginBottom: "1%" }}>Assign to -</h5>

            <div className="upper-div" style={{ marginBottom: "1%" }}>
              <select
                id="modelDropdown"
                className="form-control"
                name="Model"
                value={selectedValue}
                onChange={(e) => setSelectedValue(e.target.value)}
                required
                style={{
                  color: selectedValue === DefaultContact ? "blue" : "black", // Change the color of selected value
                }}
              >
                <option value="">- Select one -</option>
                {contactsList.map((contact, index) => (
                  <option
                    key={index + 1}
                    value={contact?.account_main_contact_id}
                    style={{
                      color: contact?.account_main_contact_id === DefaultContact ? "blue" : "black",
                    }}
                  >
                    {contact?.account_main_contact_firstname + " " + contact?.account_main_contact_lastname}
                  </option>
                ))}
              </select>
            </div>
            <div className="my-modal-section" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <label
                htmlFor="reasonForReassign"
                style={{ display: 'block', marginBottom: '8px', fontWeight: 500, fontSize: '16px' }}
              >
                Reason for Reassign *
              </label>
              <textarea
                id="reasonForReassign"
                rows="5"
                style={{
                  width: '100%',
                  padding: '8px',
                  fontSize: '14px',
                  borderColor: '#ced4da',
                  borderRadius: '4px',
                  resize: 'none',
                  flexGrow: 1,
                  height: '100%',
                }}
                value={reasonForReassign}
                placeholder="Enter reason for reassign"
                required
                onChange={(e) => setReasonForReassign(e.target.value)}
              />
            </div>

          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="row pb-1 py-1">
          <div className="col-12 content-flex-right">
            <Button variant="danger" onClick={handleClose}>Cancel</Button>
            <Button
              variant="success"
              className="ms-2"
              onClick={handleSubmit}
              disabled={loading || (selectedValue && !reasonForReassign)}
            >
              {loading ? 'Loading...' : 'Submit'}
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default SupportContactsModal;
