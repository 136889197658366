// import { Box } from '@mui/material';
import React from 'react'
// import SubHeadingOther from '../../../components/header/SubHeadingOther'
// import DataGrid, { Scrolling, Paging, Column } from 'devextreme-react/data-grid';
// import Container from "react-bootstrap/Container";
import { useState } from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CallGETAPI, CallPOSTAPI } from '../../../common/services/index';
import SubHeading from '../../../domains/accounts/components/header/SubHeading';
import Moment from 'react-moment';
import RespondModal from '../Component/respondModal/RespondModal';
import ConfirmAlert from '../Component/alerts/ConfirmAlert';
import { toast } from 'react-toastify';
import SupportContactsmodal from '../Component/SupportContactsmodal/SupportContactsmodal';
import Loading from '../../../common/components/Loader/Loading'
import { FormatDateWithTime, GetProfile, getPermission } from '../../../common/helper/Common';
import { useDispatch, useSelector } from 'react-redux';
// import edit from "../../../../public/assets/images/edit.svg"
import { DecryptToken, oldbatteryData, oldpaddata } from '../../../common/helper/BasicFn';
import TableSkeleton from '../../../common/components/Loader/TableSkeleton';
import { Skeleton } from '@mui/material';
import { isPermission } from '../../../common/helper/PermissionManager';
import AlertExample from './alerts/AlertExample';


export default function SupportDetails({ handleSetToken }) {
    // const [validated, setValidated] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [respondModal, setRespondModal] = useState(false);
    const [addContactsModal, setAddContactsModal] = useState(false);
    const [onContactsSubmitted, setOnContactsSubmitted] = useState(() => { });
    const [issueType, setIssueType] = useState("");
    const [btnLoading, setBtnLoading] = useState(true);
    const [oldBattery, setOldBattery] = useState({})
    const [newBattery, setNewBattery] = useState({})
    const [oldPad, setOldPad] = useState({})
    const [newPad, setNewPad] = useState({})
    const [formData, setFormData] = useState({
        account_id: "",
        contact_id: "",
        issue_type: "",
        assign_to: "",
        related_to: "",
        relation: "",
        due_date: "",
        issue: "",
        status: 0,
    });
    const [selectedData, setSelectedData] = useState({})
    const [relatedToList, setRelatedToList] = useState([
        { label: 'Related to 1', value: '1' },
        { label: 'Related to 2', value: '2' },
        { label: 'Related to 3', value: '3' },
    ]);
    const [issueTypeList, setIssueTypeList] = useState([
        { label: 'Issue 1', value: '1' },
        { label: 'Issue 2', value: '2' },
        { label: 'Issue 3', value: '3' },
    ]);
    const [assignedToList, setAssignedToList] = useState([
        { label: 'Contact 1', value: '1' },
        { label: 'Contact 2', value: '2' },
        { label: 'Contact 3', value: '3' },
    ]);
    const [relationList, setRelationList] = useState([
        { label: 'Relation 1', value: '1' },
        { label: 'Relation 2', value: '2' },
        { label: 'Relation 3', value: '3' },
    ]);
    const [responseData, setResponseData] = useState([]);
    const [actionData, setActionData] = useState([]);

    const { supportId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const user = DecryptToken();
    const privilege = getPermission();

    const [visibleBatteryColumns, setVisibleBatteryColumns] = useState([]);
    const [visiblePadColumns, setVisiblePadColumns] = useState([]);
    useEffect(() => {
        // handleSetToken();

    }, [])

    const breadcrumbs = useSelector(state => state.BreadCrumbs.items); // Accessing breadcrumbs from Redux store state


    const isValidValue = (value) => {
        // Add additional checks for specific invalid values
        return (
            value !== null &&
            value !== undefined &&
            value !== "0000-00-00" &&
            value != ""
        );
    };
    // filter array with array key and value
    const filterArray = (arr, value) => {
        const filteredData = arr.find((item) => {
            return item.value == value
        })
        return filteredData;
    }

    // get support data
    const fetchOnLoad = async () => {
        const result = await CallGETAPI("support/ticket-details/" + supportId);

        if (result?.status) {
            let ticketDetails = result?.data?.data
            let new_pad_data

            setOldBattery(ticketDetails?.oldbatteryData);
            setNewBattery(ticketDetails?.newbatteryData)
            setNewPad(ticketDetails?.newpadData);
            setOldPad(ticketDetails?.oldpaddata);
            setFormData(ticketDetails?.ticketDetails);
            const IsColumnVisible = (key, data) => {
                return isValidValue(data[key]);
            };

            for (let i = 0; i < ticketDetails?.newbatteryData?.length; i++) {
                const new_Battery_data = ticketDetails?.newbatteryData[i];



                const visibleColumns = oldbatteryData.filter((column) => {
                    return column.is_default === 1 || IsColumnVisible(column.key, new_Battery_data);
                });
                setVisibleBatteryColumns(visibleColumns)

            }
            for (let i = 0; i < ticketDetails?.newpadData?.length; i++) {
                const new_pad_data = ticketDetails?.newpadData[i];
                console.log("new_pad_data", new_pad_data);

                // Filter the columns in oldpaddata based on default or visibility in new_pad_data
                const visibleColumns = oldpaddata.filter((column) => {
                    return column.is_default === 1 || IsColumnVisible(column.key, new_pad_data);
                });
                setVisiblePadColumns(visibleColumns)

            }
            console.log({ visiblePadColumns, visibleBatteryColumns });

            setActionData(result?.data?.data?.actionData);
            setIssueType(result?.data?.data?.ticketDetails?.issue_type);
            setResponseData(result?.data?.data?.responseData);

            if (ticketDetails?.issue_type) {
                let filteredIssueType = filterArray(issueTypeList, ticketDetails?.issue_type);
                setSelectedData((old) => ({ ...old, ['issue_type']: filteredIssueType }));
            }
            if (ticketDetails?.assign_to) {
                let filteredIssueType = filterArray(assignedToList, ticketDetails?.assign_to);
                setSelectedData((old) => ({ ...old, ['assign_to']: filteredIssueType }));
            }
            if (ticketDetails?.related_to) {
                let filteredIssueType = filterArray(relatedToList, ticketDetails?.related_to);
                setSelectedData((old) => ({ ...old, ['related_to']: filteredIssueType }));
            }
            if (ticketDetails?.relation) {
                let filteredIssueType = filterArray(relationList, ticketDetails?.relation);
                setSelectedData((old) => ({ ...old, ['relation']: filteredIssueType }));
            }
        }
        setBtnLoading(false);
    }

    useEffect(() => {
        fetchOnLoad();
    }, [respondModal])


    const handleClose = async (reason) => {
        setBtnLoading(true)
        const payload = {
            "description": reason
        }
        const url = `support/close-ticket/${supportId}`; // Template string for the URL
        const result = await CallPOSTAPI(url, payload);

        if (result?.data?.status) {
            setConfirmModal(false)
            toast.success(result?.data?.msg);
            fetchOnLoad();
        } else {
            setConfirmModal(false)
            toast.error(result?.data?.msg);
        }
        setBtnLoading(false)
    }

    const addContacts = () => {
        setAddContactsModal(true)
    }

    const refreshSupportDetails = async () => {
        setTimeout(fetchOnLoad, 1000);

    };



    const getStatusText = (status) => {
        switch (status) {
            case 0:
                return "Not Viewed";
            case 1:
                return "Active";
            case 2:
                return "Waiting";
            case 3:
                return "Completed";
            default:
                return "Unknown Status"; // Optional: handle unexpected values
        }
    };
    return (
        <>
            <>
                <div className='mt-4 pb-5' style={{ paddingInline: "45px" }}>
                    <SubHeading title={'Support Ticket'} hideNew={true} newUrl={''} subHeading={true} hideHierarchy={true} editUrl={''} backTab={'Sites'} bottomLinks={false} breadcrumbs={breadcrumbs} />

                    {/* bottom buttons */}
                    <div className="d-flex" style={{ gap: "10px" }}>
                        <>
                            <div>

                                {Number(isPermission({ type: "btn", name: "supportRespond" })) === 1 ? (
                                    <button
                                        className="btn text-primary"
                                        type="button"
                                        onClick={() => setRespondModal(true)}
                                    >
                                        <img
                                            src="/assets/images/edit.svg"
                                            alt="Edit"
                                            style={{ marginRight: "5px" }}
                                        />
                                        <span className="ms-1">Respond</span>
                                    </button>
                                ) : ""}


                                {

                                    Number(isPermission({ type: "btn", name: "supportClose" })) === 1 &&
                                    parseInt(formData?.status) !== 0 &&
                                    parseInt(formData?.status) !== 3 && (
                                        <button
                                            className="btn text-primary"
                                            type="button"
                                            onClick={() => setConfirmModal(true)}
                                        >
                                            <img
                                                src="/assets/images/close-blue.svg"
                                                alt="Close"
                                                style={{ marginRight: "5px" }}
                                            />
                                            <span className="ms-1">Close</span>
                                        </button>
                                    )
                                }


                                {
                                    Number(user?.user_type) !== 3

                                        ? Number(isPermission({ type: "btn", name: "supportReassign" })) === 1 && (
                                            <button
                                                className="btn text-primary"
                                                type="button"
                                                onClick={() => addContacts()}
                                            >
                                                <img
                                                    src="/assets/images/reassign.svg"
                                                    alt="Reassign"
                                                    style={{ marginRight: "5px" }}
                                                />
                                                <span className="ms-1">Reassign</span>
                                            </button>
                                        )
                                        : ""
                                }



                            </div>
                        </>



                        {addContactsModal && (
                            <SupportContactsmodal
                                // DefaultContact={DefaultContact}
                                addContactsModal={addContactsModal}
                                setAddContactsModal={setAddContactsModal}
                                supportId={supportId}
                                issueType={issueType}
                                onContactsSubmitted={refreshSupportDetails} // Pass the callback function
                            />
                        )}

                    </div>

                    {/* main */}
                    <div className="text-left mt-3">
                        <h4 className='heading'>General Information</h4>
                    </div>

                    {/* table */}
                    <table className="w-100 mt-2">
                        <thead>
                            <tr className="">
                                <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">Issue Type</th>
                                <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">Relation</th>
                                <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">Created By</th>
                                <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">Created Date</th>
                                <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">Assigned to</th>
                                <th className="border border-2 py-1 px-2 bg-tbl-border border-t-blue">Support Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Initial Row with data */}
                            <tr className="">
                                <td className="border border-2 py-1 px-2 border-r-blue">
                                    {btnLoading ? (
                                        <Skeleton
                                            count={1}
                                            width={"90%"}
                                            height={20}
                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                        />
                                    ) : (
                                        // selectedData?.issue_type?.label
                                        formData?.issue_name?.issue_name


                                    )}
                                </td>
                                <td className="border border-2 py-1 px-2 border-r-blue">
                                    {btnLoading ? (
                                        <Skeleton
                                            count={1}
                                            width={"90%"}
                                            height={20}
                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                        />
                                    ) : (
                                        formData?.relation
                                    )}
                                </td>
                                <td className="border border-2 py-1 px-2 border-r-blue">
                                    {btnLoading ? (
                                        <Skeleton
                                            count={1}
                                            width={"90%"}
                                            height={20}
                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                        />
                                    ) : (
                                        formData?.created_by
                                    )}
                                </td>
                                <td className="border border-2 py-1 px-2 border-r-blue">
                                    {btnLoading ? (
                                        <Skeleton
                                            count={1}
                                            width={"90%"}
                                            height={20}
                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                        />
                                    ) : (
                                        <Moment date={formData?.created_date} format={"MM/DD/YYYY h:mm A"} />
                                    )}
                                </td>
                                <td className="border border-2 py-1 px-2 border-r-blue">
                                    {btnLoading ? (
                                        <Skeleton
                                            count={1}
                                            width={"90%"}
                                            height={20}
                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                        />
                                    ) : (
                                        formData?.owner_name
                                    )}
                                </td>
                                <td className="border border-2 py-1 px-2">
                                    {btnLoading ? (
                                        <Skeleton
                                            count={1}
                                            width={"90%"}
                                            height={20}
                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                        />
                                    ) : (
                                        getStatusText(formData?.status)
                                    )}
                                </td>
                            </tr>
                            <tr className="">
                                <th colSpan={6} className="border border-2 py-1 px-2 bg-tbl-border border-t-blue">Issue</th>
                            </tr>
                            {/* Section for Issue */}
                            {(formData?.issue !== "") ? (
                                <>
                                    <tr className="">
                                        <td colSpan={6} className="border border-2 py-1 px-2 border-b-blue" style={{ maxWidth: '500px', overflowWrap: 'break-word' }}>
                                            {btnLoading ? (
                                                <Skeleton
                                                    count={1}
                                                    width={"90%"}
                                                    height={20}
                                                    style={{ marginLeft: "2px", marginRight: "2px" }}
                                                />
                                            ) : (
                                                formData?.issue || "No data Found"
                                            )}
                                        </td>
                                    </tr>
                                </>
                            ) : (
                                <>

                                    {oldBattery?.length > 0 ? (
                                        <>
                                            <tr className="">
                                                <td className="border border-2 py-1 px-2 border-r-blue">Original Data</td>
                                            </tr>

                                            <tr className="">

                                                {visibleBatteryColumns?.map((column) => (
                                                    <th key={column.key} className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                                                        {column.title}
                                                    </th>
                                                ))}
                                            </tr>
                                            {oldBattery?.map((data, index) => (

                                                <tr key={index}>
                                                    {visibleBatteryColumns?.map((column) => (
                                                        <td key={column.key} className="border border-2 py-1 px-2 border-r-blue">
                                                            {["battery_expiration", "manufactured_date", "created_date", "install_date", "install_before_date", "install_9v_date"].includes(column.key) ? (
                                                                data[column.key] && !isNaN(new Date(data[column.key]).getTime()) ? (

                                                                    <Moment date={data[column.key]} format={"MM/DD/YYYY h:mm A"} />
                                                                ) : (
                                                                    ""
                                                                )
                                                            ) : (
                                                                ["unknown", "invalid", "", "N/A"].includes(data[column.key]) ? (
                                                                    ""
                                                                ) : (
                                                                    data[column.key]
                                                                )
                                                            )}
                                                        </td>


                                                    ))}
                                                </tr>
                                            ))}
                                        </>
                                    ) : null}
                                    {oldPad?.length > 0 ? (
                                        <>
                                            <tr className="">
                                                <td className="border border-2 py-1 px-2 border-r-blue">Original Data</td>
                                            </tr>

                                            <tr className="">

                                                {visiblePadColumns?.map((column) => (
                                                    <th key={column.key} className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                                                        {column.title}
                                                    </th>
                                                ))}
                                            </tr>


                                            {oldPad?.map((data, index) => (
                                                <tr key={index}>
                                                    {visiblePadColumns?.map((column) => (
                                                        <td key={column.key} className="border border-2 py-1 px-2 border-r-blue">
                                                            {["pad_expiration", "manufactured_date", "created_date"].includes(column.key) ? (
                                                                data[column.key] && !isNaN(new Date(data[column.key]).getTime()) ? (

                                                                    <Moment date={data[column.key]} format={"MM/DD/YYYY h:mm A"} />
                                                                ) : (
                                                                    ""
                                                                )
                                                            ) : (
                                                                ["unknown", "invalid", "", "N/A"].includes(data[column.key]) ? (
                                                                    ""
                                                                ) : (
                                                                    data[column.key]
                                                                )
                                                            )}
                                                        </td>

                                                    ))}
                                                </tr>

                                            ))}

                                        </>
                                    ) : null}


                                    {newPad?.length > 0 ? (
                                        <>

                                            <tr className="">
                                                <td className="border border-2 py-1 px-2 border-r-blue">Updated Data</td>
                                            </tr>

                                            <tr className="">

                                                {visiblePadColumns?.map((column) => (
                                                    <th key={column.key} className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                                                        {column.title}
                                                    </th>
                                                ))}
                                            </tr>


                                            {newPad?.map((data, index) => (
                                                <tr key={index}>
                                                    {visiblePadColumns?.map((column) => (
                                                        <td key={column.key} className="border border-2 py-1 px-2 border-r-blue">
                                                            {["pad_expiration", "manufactured_date", "created_date"].includes(column.key) ? (
                                                                data[column.key] && !isNaN(new Date(data[column.key]).getTime()) ? (

                                                                    <Moment date={data[column.key]} format={"MM/DD/YYYY h:mm A"} />
                                                                ) : (
                                                                    ""
                                                                )
                                                            ) : (
                                                                ["unknown", "invalid", "", "N/A"].includes(data[column.key]) ? (
                                                                    ""
                                                                ) : (
                                                                    data[column.key]
                                                                )
                                                            )}
                                                        </td>

                                                    ))}
                                                </tr>


                                            ))}

                                        </>
                                    ) : null}
                                    {newBattery.length > 0 ? (
                                        <>
                                            <tr className="">
                                                <td className="border border-2 py-1 px-2 border-r-blue">Updated Data</td>
                                            </tr>
                                            <tr className="">

                                                {visibleBatteryColumns?.map((column) => (
                                                    <th key={column.key} className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">
                                                        {column.title}
                                                    </th>
                                                ))}
                                            </tr>

                                            {newBattery?.map((data, index) => (

                                                <tr key={index}>
                                                    {visibleBatteryColumns?.map((column) => (
                                                        <td key={column.key} className="border border-2 py-1 px-2 border-r-blue">
                                                            {["battery_expiration", "manufactured_date", "created_date", "install_date", "install_before_date", "install_9v_date"].includes(column.key) ? (
                                                                data[column.key] && !isNaN(new Date(data[column.key]).getTime()) ? (

                                                                    <Moment date={data[column.key]} format={"MM/DD/YYYY h:mm A"} />
                                                                ) : (
                                                                    ""
                                                                )
                                                            ) : (
                                                                ["unknown", "invalid", "", "N/A"].includes(data[column.key]) ? (
                                                                    ""
                                                                ) : (
                                                                    data[column.key]
                                                                )
                                                            )}
                                                        </td>

                                                    ))}
                                                </tr>
                                            ))}
                                        </>
                                    ) : null}
                                </>
                            )}
                        </tbody>
                    </table>


                    {actionData?.length > 0 ?
                        <>
                            <div className="text-left mt-3">
                                <h4 className='heading'>Actions</h4>
                            </div>

                            <table className="w-100 mt-2">
                                <thead>
                                    <tr className="">
                                        <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">User Name</th>
                                        <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">Action</th>
                                        <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">Date</th>
                                        <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">Result</th>
                                        <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">Notes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {btnLoading ? (
                                        [...Array(5)].map((_, index) => (
                                            <tr key={index}>
                                                <td className="border border-2 py-1 px-2 border-r-blue">
                                                    <Skeleton
                                                        count={1}
                                                        width={"90%"}
                                                        height={20}
                                                        style={{ marginLeft: "2px", marginRight: "2px" }}
                                                    />
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-r-blue">
                                                    <Skeleton
                                                        count={1}
                                                        width={"90%"}
                                                        height={20}
                                                        style={{ marginLeft: "2px", marginRight: "2px" }}
                                                    />
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-r-blue">
                                                    <Skeleton
                                                        count={1}
                                                        width={"90%"}
                                                        height={20}
                                                        style={{ marginLeft: "2px", marginRight: "2px" }}
                                                    />
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-r-blue">
                                                    <Skeleton
                                                        count={1}
                                                        width={"90%"}
                                                        height={20}
                                                        style={{ marginLeft: "2px", marginRight: "2px" }}
                                                    />
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-r-blue">
                                                    <Skeleton
                                                        count={1}
                                                        width={"90%"}
                                                        height={20}
                                                        style={{ marginLeft: "2px", marginRight: "2px" }}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        actionData.map((t, index) => (
                                            <tr key={index}>
                                                <td className="border border-2 py-1 px-2 border-r-blue">
                                                    {btnLoading ? (
                                                        <Skeleton
                                                            count={1}
                                                            width={"90%"}
                                                            height={20}
                                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                                        />
                                                    ) : (
                                                        t?.user_name
                                                    )}
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-r-blue">
                                                    {btnLoading ? (
                                                        <Skeleton
                                                            count={1}
                                                            width={"90%"}
                                                            height={20}
                                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                                        />
                                                    ) : (
                                                        t?.action
                                                    )}
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-r-blue">
                                                    {btnLoading ? (
                                                        <Skeleton
                                                            count={1}
                                                            width={"90%"}
                                                            height={20}
                                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                                        />
                                                    ) : (
                                                        <Moment date={t?.created_date} format={'MM/DD/YYYY h:mm A'} />
                                                    )}
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-r-blue">
                                                    {btnLoading ? (
                                                        <Skeleton
                                                            count={1}
                                                            width={"90%"}
                                                            height={20}
                                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                                        />
                                                    ) : (
                                                        t?.result
                                                    )}
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-r-blue">
                                                    {btnLoading ? (
                                                        <Skeleton
                                                            count={1}
                                                            width={"90%"}
                                                            height={20}
                                                            style={{ marginLeft: "2px", marginRight: "2px" }}
                                                        />
                                                    ) : (
                                                        t?.description
                                                    )}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>


                            </table>
                        </>

                        : ""}

                    {responseData?.length > 0 && (

                        <>

                            <div className="text-left mt-3">
                                <h4 className='heading'>Responses</h4>
                            </div>

                            <table className="w-100 mt-2">
                                <thead>
                                    <tr className="">
                                        <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">User Name</th>
                                        <th className="border border-2 py-1 px-2 bg-tbl-border border-r-blue border-t-blue">Response Date</th>
                                        <th className="border border-2 py-1 px-2 bg-tbl-border border-t-blue">Issue</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {btnLoading ? (
                                        // Render skeletons in each <td> for loading state
                                        [...Array(5)].map((_, index) => (
                                            <tr className="" key={index}>
                                                <td className="border border-2 py-1 px-2 border-r-blue border-b-blue">
                                                    <Skeleton
                                                        count={1}
                                                        width={"90%"}
                                                        height={20}
                                                        style={{ marginLeft: "2px", marginRight: "2px" }}
                                                    />
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-r-blue border-b-blue">
                                                    <Skeleton
                                                        count={1}
                                                        width={"90%"}
                                                        height={20}
                                                        style={{ marginLeft: "2px", marginRight: "2px" }}
                                                    />
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-b-blue">
                                                    <Skeleton
                                                        count={1}
                                                        width={"90%"}
                                                        height={20}
                                                        style={{ marginLeft: "2px", marginRight: "2px" }}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        // Render the actual data if not loading
                                        responseData?.map((response, index) => (
                                            <tr className="" key={index}>
                                                <td className="border border-2 py-1 px-2 border-r-blue border-b-blue">
                                                    {response?.created_by}
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-r-blue border-b-blue">
                                                    <Moment date={response?.created_date} format={'MM/DD/YYYY h:mm A'} />
                                                </td>
                                                <td className="border border-2 py-1 px-2 border-b-blue">
                                                    {response?.information}
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>



                            </table>

                        </>
                    )}
                </div>

                <div className="pb-5" style={{ marginTop: "96px" }}>
                    <div className="d-flex justify-content-between">
                        <div>
                            <span>
                                Created: {formData?.created_date ? `${formData?.created_by} ${FormatDateWithTime(formData?.created_date)}` : ''}
                            </span>
                        </div>
                        <div>
                            <span>
                                Modified: {formData?.modified_date ? `${formData?.modified_by} ${FormatDateWithTime(formData?.modified_date)}` : ''}
                            </span>
                        </div>
                    </div>
                </div>

            </>

            {/* respondmodal */}
            <RespondModal
                respondModal={respondModal}
                setRespondModal={setRespondModal}
            />

            <AlertExample
                show={confirmModal}
                handleClose={() => setConfirmModal(false)}
                msg={"Are you sure? you want to close this issue"}
                handleOk={handleClose}
                loading={btnLoading}
            />
            {/* 
            <ConfirmAlert
                show={confirmModal}
                handleClose={() => setConfirmModal(false)}
                msg={"Are you sure? you want to close this issue"}
                handleOk={handleClose}
                loading={btnLoading}
            /> */}
        </>
    )
}
