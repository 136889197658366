// apiSlice.js
import { createSlice } from '@reduxjs/toolkit';

const AccountDataSlice = createSlice({
    name: 'Account',
    initialState: {
        data: [],

        error: null,
    },
    reducers: {

        setData: (state, action) => {
            console.log("checking redux slice", action.payload)
            const { accountdetail, Is_AccountList } = action.payload;
            console.log("i am from redux", Is_AccountList)
            state.data = {
                ...state.data,
                accountdetail,
                Is_AccountList,
            };
        },

        setDataList: (state, action) => {
            console.log("checking redux slice", action.payload)
            const { accountList } = action.payload;
            console.log("i am from redux", accountList)
            state.data = {
                ...state.data,
                accountList,

            };
        },

    },
});

export const { setData, setDataList } = AccountDataSlice.actions;
export default AccountDataSlice.reducer;
