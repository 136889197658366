import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { fetchPieChartData, findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";

// Register required Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

// Function to generate a random color in HEX format
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const DeliquentCheckHalfPieChart = ({ sections, setSections }) => {
  const [piedata, setPiedata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const account = findSectionById("accounts", sections);

  const fetchData = async () => {
    try {
      const result = await fetchPieChartData(
        sections,
        "deliquentCheck",
        "delinquentChecks"
      );
      setPiedata(result);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const labels = piedata?.map((item) => item.account_site_name) || [];

  const dataValues = piedata?.map((item) => item.aed_count) || [];

  // Fixed colors for the first 10 elements
  const fixedColors = [
    "#FF6384",
    "#36A2EB",
    "#FFCE56",
    "#4CAF50",
    "#FF9F40",
    "#9966FF",
    "#C9CBCF",
    "#FF6384",
    "#4BC0C0",
    "#FFCD56",
  ];

  // Generate random colors for the remaining elements
  const randomColors = Array.from({ length: 10 }, getRandomColor);

  // Combine fixed and random colors
  const backgroundColor = [...fixedColors, ...randomColors];

  const data = {
    labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor,
        hoverBackgroundColor: backgroundColor,
      },
    ],
  };

  // Options to hide the legend and make it a half pie chart
  const options = {
    rotation: -90, // Start the chart from the top
    circumference: 180, // Half pie
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      tooltip: {
        enabled: true, // Enable tooltips
      },
    },
  };
  const hasAEDs = piedata?.some((item) => item.aed_count > 0);
  const navigateDeliquentCheck = () => {
    Number(isPermission({ type: "dashboard", name: "EquipmentListing" })) ===
      1 && navigate(`/account-details/${piedata[0]?.account_id}/Equipment`);
  };

  return (
    <>
      {hasAEDs ? (
        <div id="deliquentChart-Section">
          <div className="delinquent-checks-container">
            <h4 className="delinquent-checks-title">Delinquent Checks</h4>
            <div className="delinquent-checks-content">
              <div className="chart-container">
                <Pie data={data} options={options} />
              </div>
              <div className="site-list">
                {labels.map((label, index) => (
                  <p
                    key={index}
                    className="site-label"
                    style={{
                      color: backgroundColor[index],
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                      margin: "4px 0",
                    }}
                    onClick={() => navigateDeliquentCheck()}
                  >
                    <span
                      style={{
                        width: "12px",
                        height: "12px",
                        backgroundColor: backgroundColor[index],
                        display: "inline-block",
                        borderRadius: "50%",
                      }}
                    />
                    {label}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DeliquentCheckHalfPieChart;
