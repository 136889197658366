import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button as FixedButton } from "@mui/material";
// import Filter from "../filter/equipmentIndex";

import Drawer from "@mui/material/Drawer";
import { useSelector, useDispatch } from "react-redux";

// New Imports

import { CallGETAPI, CallPOSTAPI } from "../../../common/services";
import EquipmentNoDataTbl from "../../accounts/components/skeleton/table/EquipmentNoDataTbl";
import TopAedNewMove from "../../Aed/NewAed/components/AedButtons/TopAedNewMove";
import SingleSiteName from "../../accounts/components/SingleSiteName";
import {
  removeFilterData,
  removePayloadData,
} from "../../../store/slices/AccountDetailsEquipmentFilter";
import AEDStandAlone from "../../../common/components/tables/AEDs/AEDStandAlone";
import AEDOutOfServiceTbl from "../../../common/components/tables/AEDs/AEDOutOfServiceTbl";
import {
  CalculateAEDList,
  DecryptToken,
  FetchAEDBySiteListData,
  FetchAEDListData,
  FetchAEDListPending,
  FetchAEDOFS,
} from "../../../common/helper/BasicFn";

import { getPermission } from "../../../common/helper";
import { isPermission } from "../../../common/helper/PermissionManager";
import AEDTable from "../../../common/components/tables/AEDs/AEDTable";
import AedFilter from "../components/tabComponents/EquipmentListing/AedFilter";
import TableSkeleton from "../../accounts/components/skeleton/table/AedsSkeleton";

export default function SiteAeds({
  NewSupport,
  tabTbldata,
  setTabTbldata,
  accountName = "",
  accountId = "",
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = DecryptToken();
  const { siteId } = useParams();
  const [showLoading, setShowLoading] = useState(true);
  const [showAedTbl, setShowAedTbl] = useState(true);
  const [showAccTbl, setShowAccTbl] = useState(true);
  const [aedList, setAedList] = useState([]);
  const [pendingaeds, setPendingaeds] = useState([]);
  const [outofServiceData, setOutofServiceData] = useState([]);
  const [data, setData] = useState();
  const equipmentFilterData = useSelector(
    (state) => state.accountdetailsequipmentfilter.equipmentFilterData
  );

  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleAedPendingList = async () => {
    const result = {}; // await FetchAEDListPending(accountId);

    if (result?.status) {
      const pendingaeds = result?.data;
      const resultArr = CalculateAEDList(pendingaeds);
      setPendingaeds(resultArr);
    }
  };

  const handleAedList = async () => {
    const result = await FetchAEDBySiteListData(siteId);

    if (result?.status) {
      const resultArr = CalculateAEDList(result?.data);
      setAedList(resultArr);
    }
  };

  const handleOutOfService = async () => {
    const result = await FetchAEDOFS(accountId);

    if (result?.status) {
      const OFDArr = CalculateAEDList(result?.data);
      const OFD = [];
      for (let OFi = 0; OFi < OFDArr?.length; OFi++) {
        const el = OFDArr[OFi];
        for (let OF2 = 0; OF2 < el.data.length; OF2++) {
          const element = el.data[OF2];
          const obj = {
            site_name: el?.site_name,
            site_id: el?.site_id,
            standalone_data: el?.standalone_data || [],
            ...element,
          };
          OFD.push(obj);
        }
      }
      setOutofServiceData(OFD);
    }
  };
  // on load fetch data
  useEffect(() => {
    setShowLoading(true);

    Promise.all([
      // handleAedPendingList(),
      handleAedList(),
      // handleOutOfService(),
    ]).then(() => {
      setShowLoading(false);
    });
  }, []);
  const [openMoveModal, setOpenMoveModal] = useState(false);

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "asc",
  });

  const sortTable = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }

    const sortedData = [...data].sort((a, b) => {
      let valA = a[key];
      let valB = b[key];

      if (typeof valA === "string") {
        valA = valA.toLowerCase();
        valB = valB.toLowerCase();
      }

      if (valA < valB) {
        return direction === "asc" ? -1 : 1;
      }
      if (valA > valB) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    setData(sortedData);
    setSortConfig({ key, direction });
  };

  const handleClearFilterData = async () => {
    dispatch(removeFilterData());
    dispatch(removePayloadData());
    setShowLoading(true);
    const result = await CallGETAPI(
      "account/get-aed-with-standalon/" + accountId
    );
    if (result?.data?.status) {
      // setTblsData(result);
    }
    setShowLoading(false);
  };

  const RenderAedData = (dataList, pending = false) => (
    <>
      {dataList?.map((item, index) => (
        <div key={index}>
          <div className="row w-100 EquipmentTab">
            <div className="text-center p-0 site-title-btn EquipmentHeaderDiv">
              <SingleSiteName
                equipmentFilterData={equipmentFilterData}
                item={item}
              />
              {equipmentFilterData && equipmentFilterData?.aed === false
                ? null
                : item?.data.length !== 0 && (
                  <div className="right-btns d-flex align-items-center absolute right-0 btn-section btnsDiv">
                    <TopAedNewMove
                      accountId={accountId}
                      accountName={accountName}
                      siteId={item?.site_id}
                      siteName={item?.site_name}
                      isTechnician={item?.is_technician}
                      moveAed={1}
                      isserviceCheck={item?.site_name === "Pending" ? 0 : 1}
                      hideAccMove={item?.standalone_data?.length > 0 ? 0 : 1}
                      hideAedMove={item?.data?.length > 0 ? 0 : 1}
                    />
                  </div>
                )}
            </div>
          </div>
          <div className="">
            {equipmentFilterData &&
              equipmentFilterData?.aed === false ? null : item?.data.length ===
                0 ? (
              <EquipmentNoDataTbl
                accountId={accountId}
                accountName={accountName}
                siteId={item?.site_id}
                siteName={item?.site_name}
              />
            ) : (
              <AEDTable
                accountId={accountId}
                SingleAED={item?.data}
                setShowAedTbl={setShowAedTbl}
                tabTbldata={tabTbldata}
                setTabTbldata={setTabTbldata}
              />
            )}
            {user.user_type === 3 ? "" : (
            <>
            {Number(isPermission({ type: "tbl", name: "accessoryTbl" })) === 1 &&
            equipmentFilterData &&
              equipmentFilterData?.accessories === false ? null : item
                ?.standalone_data.length === 0 ? null : (
              <AEDStandAlone
                siteId={item?.site_id}
                siteName={item?.site_name}
                accountId={accountId}
                standaloneData={item?.standalone_data}
                setShowAccTbl={setShowAccTbl}
                tabTbldata={tabTbldata}
                setTabTbldata={setTabTbldata}
                accountName={accountName}
              />
            )}
            </>
            )}
          </div>
        </div>
      ))}
    </>
  );

  return (
    <div className="relative" style={{ width: "100%", marginBottom: "5%" }}>
      <>
        <div>
          {equipmentFilterData &&
            Object.keys(equipmentFilterData).length !== 0 ? (
            <div style={{ position: "relative !important" }}>
              <FixedButton
                className="btn-style-cancel-filter"
                onClick={handleClearFilterData}
              >
                Clear Filter
              </FixedButton>
            </div>
          ) : null}

          <Drawer
            sx={{
              width: "300px",
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: "300px",
                boxSizing: "border-box",
              },
            }}
            anchor="right"
            open={open}
            onClose={handleDrawerClose}
          >
            {/* filter component  */}
            <AedFilter
              setOpen={setOpen}
              setShowLoading={setShowLoading}
              accountId={accountId}
              accountListingPage={true}
              // setTblsData={setTblsData}
              setShowAedTbl={setShowAedTbl}
              setShowAccTbl={setShowAccTbl}
              tabTbldata={tabTbldata}
              setTabTbldata={setTabTbldata}
            />
          </Drawer>
        </div>
        {pendingaeds?.length > 0 && RenderAedData(pendingaeds, true)}

        {/* {aedList?.length > 0 && RenderAedData(aedList)} */}
        {aedList?.length > 0 ? RenderAedData(aedList) : !showLoading ? <EquipmentNoDataTbl /> : "" }

        {(pendingaeds?.length > 0 || aedList?.length > 0) &&
          outofServiceData?.length > 0 &&
          (equipmentFilterData &&
            equipmentFilterData?.aed ===
            false ? null : outofServiceData.length === 0 ? null : (
              <div className="">
                <div className="row w-100">
                  <div className="col-md-12 p-0 text-center site-title-btn">
                    <h2 className="aed-title OutOfServiceTitle">
                      Out of Service
                    </h2>
                  </div>
                </div>
                <div className="">
                  <AEDOutOfServiceTbl SingleAED={outofServiceData} />
                </div>
              </div>
            ))}

        {showLoading ? <TableSkeleton /> : null}
      </>
    </div>
  );
}
