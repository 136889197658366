import React, { useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";

const SitesCard = ({ sections }) => {
  const [sites, setSites] = useState({});
  const navigate = useNavigate();
  const id = "sites";
  const account = findSectionById("accounts", sections);

  const navigateSite = () => {
    if (!account?.account_id) {
      Number(isPermission({ type: "dashboard", name: "SiteListing" })) === 1 &&
        navigate("/Admin/Sites-listing");
    } else if (Number(sites?.totalcount) > 1) {
      Number(isPermission({ type: "dashboard", name: "SiteListing" })) === 1 &&
        navigate(`/account-details/${account?.account_id}/Sites`);
    } else {
      Number(isPermission({ type: "dashboard", name: "SiteDetails" })) === 1 &&
        navigate(`/account/site-details/${sites?.site_id}/Details`);
    }
  };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setSites(matched);
  }, [sections]);

  return (
    <div className="p-4 rounded-lg">
      <h3
        className="text-center text-lg font-bold mb-2"
        style={{ cursor: Number(sites?.totalcount) > 0 ? "pointer" : "" }}
        onClick={() => Number(sites?.totalcount) > 0 && navigateSite()}
      >
        {sites?.totalcount}
      </h3>
      <p className="text-center">Sites</p>
    </div>
  );
};

export default SitesCard;
