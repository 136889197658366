import React, { useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";

const AedsCard = ({ sections }) => {
  const [aeds, setAeds] = useState({});
  const navigate = useNavigate();
  const id = "aeds";
  const account = findSectionById("accounts", sections);

  const navigateAeds = () => {
    //  const account = findSectionById("accounts", sections);
    if (!account?.account_id) {
      Number(isPermission({ type: "dashboard", name: "EquipmentListing" })) ===
        1 && navigate("/Admin/Aed-listing");
    } else if (Number(aeds?.totalcount) > 1) {
      Number(isPermission({ type: "dashboard", name: "EquipmentListing" })) ===
        1 && navigate(`/account-details/${account?.account_id}/Equipment`);
    } else {
      Number(isPermission({ type: "dashboard", name: "AedDetails" })) === 1 &&
        navigate(`/account/aed-details/${aeds?.aed_id}/Details`);
    }
  };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setAeds(matched);
  }, [sections]);

  return (
    <div className="p-4 rounded-lg">
      <h3
        className="text-center text-lg font-bold mb-2"
        style={{ cursor: Number(aeds?.totalcount) > 0 ? "pointer" : "" }}
        onClick={() => Number(aeds?.totalcount) > 0 && navigateAeds()}
      >
        {aeds?.totalcount}
      </h3>
      <p className="text-center">Aeds</p>
    </div>
  );
};

export default AedsCard;
