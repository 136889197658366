import React from 'react';
import CustomToggleButton2 from '../../../../../../common/components/toggleSwitch/CustomToggleButton2';

const DynamicTable = ({ columns, data,battery_information_toggle }) => {
  // Function to check if a column has any non-null values
  const hasNonNullValues = (key) => {
    return data.some(row => row[key] != null && row[key] !== '');
  };

  // Filter columns to display
  const displayColumns = columns.filter(col => 
    (col.is_default === 1 && hasNonNullValues(col.key)) || 
    (col.is_default === 0 && hasNonNullValues(col.key))
  );

  // Sort data: is_spare = 0 first, then is_spare = 1
  const sortedData = [...data].sort((a, b) => a.is_spare - b.is_spare);

  return (
    <div className="table-responsive">
          <table className="theme-table">
        <tbody>
          <tr>
            <td>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <div>
                  {!battery_information_toggle
                    ? "Please Correct Below Information"
                    : "Is below information correct?"}
                </div>
                <div style={{ 
                  display: "flex", 
                  // marginLeft: "74.1%"
                   }}>
                  <CustomToggleButton2
                    ToggleName="battery_information_toggle"
                    ToggleValue={battery_information_toggle}
                    changeHandler={()=>{}}
                    is_read_only={true}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table className="table table-striped table-bordered">
        <thead>
          <tr className="table-light">
            <th scope="col">Battery Type</th>
            {displayColumns.map((col) => (
              <th key={col.key} scope="col">{col.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, index) => (
            <tr key={index}>
              <td>{row.is_spare === 1 ? "Spare" : "Main"}</td>
              {displayColumns.map((col) => (
                <RenderTd row={row} col={col} key={col.key} />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const RenderTd = ({ row, col }) => {
  if (col.key === "is_spare") {
    return <td>{row[col.key] === 1 ? "Spare" : "Main"}</td>
  }
  if(col.renderRow){
    return <td> { row[col.key] != null && row[col.key] !== '' ? col.renderRow(row[col.key]) : 'N/A'}</td> 
  }
  return (
    <td>
      {row[col.key] != null && row[col.key] !== '' ? String(row[col.key]) : 'NA'}
    </td>
  )
}

export default DynamicTable;