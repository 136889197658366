import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CustomToggleButton from "../../../../common/components/toggleSwitch/CustomToggleButton";
import { toast } from "react-toastify";
import { CallPOSTAPI } from "../../../../common/services";

const ContactUnassignModal = ({ show, setShow, data, ContinueMove }) => {
  const [loading, setLoading] = useState(false);
  const [newData, setNewData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [newArray, setNewArray] = useState({
    equipments: [],
    unAssigned: [],
  });

  useEffect(() => {
    const parsedData = data.map((item) => ({
      ...item,
      assign_user: item.assign_user ? JSON.parse(item.assign_user) : [],
      isToggled: true,
    }));
    setNewData(parsedData);
    setOriginalData(parsedData);
  }, [data]);

  useEffect(() => {
    const updatedEquipments = newData.map((item) => ({
      aed_id: item.aed_id,
      users: item.assign_user,
    }));

    setNewArray((prevState) => ({
      ...prevState,
      equipments: updatedEquipments,
    }));
  }, [newData]);

  const handleToggleChange = (e, aed_id) => {
    setNewData((prevData) => {
      return prevData.map((item) => {
        if (item.aed_id === aed_id) {
          const isToggledOn = !item.isToggled;
          const originalItem = originalData.find((i) => i.aed_id === aed_id);

          return {
            ...item,
            assign_user: isToggledOn ? originalItem.assign_user : null,
            isToggled: isToggledOn,
          };
        }
        return item;
      });
    });
  };

  const handleModalSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const response = await CallPOSTAPI("aed/assign-aed-to-user", newArray);
    setLoading(false);
    setShow(false);

    // Check if all items have assign_user as null
    const hasUnassignedUser = newData.every((item) => item.assign_user === null);
    if (hasUnassignedUser) {
      ContinueMove();
    } else {
      toast.error("Please unassign the contact before proceeding.");
      return;
    }

    if (response.data.status) {
      toast.success("Contact unassigned successfully.");
    } else {
      toast.error(response?.data?.msg);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        // dialogClassName="modal-90w"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Unassign Contacts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>AED Brand</th>
                <th>Serial Number</th>
                <th>Primary</th>
                <th>Backup 1</th>
                <th>Backup 2</th>
                <th>Backup 3</th>
                <th style={{ width: "10px" }}>Unassign</th>
              </tr>
            </thead>
            <tbody>
              {newData &&
                newData.map((item, index) => {
                  const primary =
                    item.assign_user?.find((i) => i.type === "primary")
                      ?.primary || "N/A";
                  const backup1 =
                    item.assign_user?.find((i) => i.type === "backup1")
                      ?.backup1 || "N/A";
                  const backup2 =
                    item.assign_user?.find((i) => i.type === "backup2")
                      ?.backup2 || "N/A";
                  const backup3 =
                    item.assign_user?.find((i) => i.type === "backup3")
                      ?.backup3 || "N/A";

                  return (
                    <tr key={index}>
                      <td>{item.aed_brand_model}</td>
                      <td>{item.serial_number}</td>
                      <td>{primary}</td>
                      <td>{backup1}</td>
                      <td>{backup2}</td>
                      <td>{backup3}</td>
                      <td>
                        <CustomToggleButton
                          ToggleName="unassign_contact"
                          ToggleValue={item?.isToggled}
                          changeHandler={(e) =>
                            handleToggleChange(e, item.aed_id)
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-danger mt-0"
              type="button"
              onClick={() => setShow(false)}
            >
              Cancel
            </button>
            &nbsp;
            <Button
              variant="success"
              onClick={handleModalSubmit}
              className="mt-0"
              disabled={loading}
            >
              {loading ? "Loading..." : "Continue"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactUnassignModal;
