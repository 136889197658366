import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  fetchPieChartData,
  fetchPieChartdataValues,
  supportPermission,
} from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";

ChartJS.register(ArcElement, Tooltip, Legend);

function SupportHalfPieChart({ sections, setSections }) {
  const [piedata, setPiedata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const result = await fetchPieChartData(
        sections,
        "support",
        "supportTickets"
      );
      setPiedata(result);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Only collect valid data points
  const chartData =
    piedata?.reduce((acc, item) => {
      const dataPoints = [
        {
          label: "Created Owned Ticket",
          value: item.created_owned_ticket_count,
          permission: "created_owned_ticket_count",
          color: "#FF6384",
        },
        {
          label: "Responses Needed",
          value: item.reponse_needed_ticket_count,
          permission: "reponse_needed_ticket_count",
          color: "#36A2EB",
        },
        {
          label: "Owned Open Tickets",
          value: item.open_owned_ticket_count,
          permission: "open_owned_ticket_count",
          color: "#FFCE56",
        },
        {
          label: "Owned Not Viewed",
          value: item.owned_not_viewed_count,
          permission: "owned_not_viewed_count",
          color: "#4CAF50",
        },
        {
          label: "Owned Past Due",
          value: item.owned_past_due_count,
          permission: "owned_past_due_count",
          color: "#FF9F40",
        },
        {
          label: "Closed Tickets",
          value: item.closed_ticket_count,
          permission: "closed_ticket_count",
          color: "#9966FF",
        },
      ].filter(
        (point) =>
          point.value > 0 && Number(supportPermission(point.permission)) === 1
      );

      return dataPoints;
    }, []) || [];

  const data = {
    labels: chartData.map((item) => item.label),
    datasets: [
      {
        data: chartData.map((item) => item.value),
        backgroundColor: chartData.map((item) => item.color),
        hoverBackgroundColor: chartData.map((item) => item.color),
      },
    ],
  };

  const options = {
    rotation: -90,
    circumference: 180,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="support-half-pie-chart">
      <h4>Support Tickets</h4>
      <div className="chart-content">
        <div className="chart-container" style={{ height: "auto" }}>
          <Pie data={data} options={options} />
        </div>
        <div className="label-list">
          {chartData.map((item, index) => (
            <p
              key={index}
              className="label-item"
              style={{
                color: item.color,
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                margin: "4px 0",
              }}
              onClick={() =>
                Number(
                  isPermission({ type: "dashboard", name: "SupportListing" })
                ) === 1 && navigate("/account/support-listing")
              }
            >
              <span
                style={{
                  width: "12px",
                  height: "12px",
                  backgroundColor: item.color,
                  display: "inline-block",
                  borderRadius: "50%",
                }}
              />
              {item.label}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SupportHalfPieChart;
