import React, { useState } from "react";

const RangeDatePicker = ({ 
  formData, 
  setFormData, 
}) => {

  const handleStartDateChange = (e) => {
    const value = e.target.value;
    if (!formData?.endDate || new Date(value) <= new Date(formData?.endDate)) {
      setFormData((prevData) => ({
        ...prevData,
        startDate: value,
      }));
    }
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    if (!formData?.startDate || new Date(value) >= new Date(formData?.startDate)) {
      setFormData((prevData) => ({
        ...prevData,
        endDate: value,
      }));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "1rem",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label style={{ fontWeight: "bold" }}>From: </label>
        <input
          className="range-date-picker-input"
          type="date"
          id="start_date"
          value={formData?.startDate || ""}
          onChange={handleStartDateChange}
          required
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <label style={{ fontWeight: "bold" }}>To: </label>
        <input
          className="range-date-picker-input"
          type="date"
          id="end_date"
          value={formData?.endDate || ""}
          onChange={handleEndDateChange}
          disabled={!formData?.startDate}
          required
        />
      </div>
    </div>
  );
};

export default RangeDatePicker;
