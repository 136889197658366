import React, { memo, useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";

const ContactsCard = memo(({ sections }) => {
  const [contacts, setContacts] = useState({});
  const navigate = useNavigate();
  const id = "contacts";
  const account = findSectionById("accounts", sections);

  const navigateContact = () => {
    if (!account?.account_id) {
      Number(isPermission({ type: "dashboard", name: "ContactListing" })) ===
        1 && navigate("/Admin/Contact-listing");
    } else if (Number(contacts?.totalcount) > 1) {
      Number(isPermission({ type: "dashboard", name: "ContactListing" })) ===
        1 && navigate(`/account-details/${account?.account_id}/Contacts`);
    } else {
      navigate(
        Number(isPermission({ type: "dashboard", name: "ContactDetails" })) ===
          1 &&
          `/account/${account?.account_id}/contact-details/${contacts?.contact_id}/Details`
      );
    }
  };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setContacts(matched);
  }, [sections]);

  return (
    <div className="p-4 rounded-lg">
      <h3
        className="text-center text-lg font-bold mb-2"
        style={{ cursor: Number(contacts?.totalcount) > 0 ? "pointer" : "" }}
        onClick={() => Number(contacts?.totalcount) > 0 && navigateContact()}
      >
        {contacts?.totalcount}
      </h3>
      <p className="text-center">Contacts</p>
    </div>
  );
});

export default ContactsCard;
