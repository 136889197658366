import React, { useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { TRUE } from "node-sass";
import { useDispatch } from "react-redux";
import { addNewBreadcrumbs } from "../../../../store/slices/breadcrumbsSlice";
import { isPermission } from "../../../../common/helper/PermissionManager";

const AedCheckCard = ({ sections }) => {
  const [aedsCheck, setAedsCheck] = useState({});
  const id = "aedChecks";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const account = findSectionById("accounts", sections);
  console.log({ account });
  const handleAedDispatch = () => {
    const BreadCrumbsObject = {
      title: "",
    };
    const handleDispatch = () => {
      BreadCrumbsObject.title = "Dashboard";
      dispatch(
        addNewBreadcrumbs({
          ...BreadCrumbsObject,
          url: `/DND-dashboard`,
        })
      );
    };
    handleDispatch();
    BreadCrumbsObject.title = "Aed Check";

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/aed/aed-check-card`,
      })
    );
  };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setAedsCheck(matched);
  }, [sections]);

  return (
    <div className="p-4 rounded-lg">
      <h3
        className=" font-bold mb-2 text-center"
        style={{
          fontFamily: "arial",
          fontWeight: "700",
        }}
      >
        AED Checks Upcoming / Past Due
      </h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          justifyContent: "center",
          gap: "5%",
          marginTop: "2%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3
            className="font-bold mb-2 text-center"
            style={{ cursor: aedsCheck?.primary > 0 ? "pointer" : "" }}
            onClick={() => {
              if (Number(aedsCheck?.primary) > 1) {
                handleAedDispatch();
                navigate(`/account/aed/aed-check-card`, {
                  state: {
                    Primary: true,
                    Backup: false,
                    PrimaryAedId: aedsCheck?.primary_ids,
                  },
                });
              } else if (Number(aedsCheck?.primary) === 1) {
                handleAedDispatch();
                navigate(
                  `/account/aed-details/check-select?aed_id=${aedsCheck?.primary_ids[0]}&account_id=${aedsCheck?.primaryaccount_id}`
                );
              }
            }}
          >
            {aedsCheck?.primary}
          </h3>

          <h6 className="mt-2 mb-2 text-center">Primary</h6>
        </div>

        <div
          style={{
            width: "2px",
            height: "70px",
            backgroundColor: "black",
            margin: "0 10px",
          }}
        />
        {/* {JSON.stringify(aedsCheck)} */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h3
            className=" font-bold mb-2 text-center "
            style={{ cursor: aedsCheck?.backup > 0 ? "pointer" : "" }}
            onClick={() => {
              if (
                Number(aedsCheck?.backup) > 1 ||
                (aedsCheck?.primary > 0 && aedsCheck?.backup >= 1)
              ) {
                handleAedDispatch();
                navigate("/account/aed/aed-check-card", {
                  state: {
                    Primary: aedsCheck?.primary > 0 ? true : false,
                    Backup: true,
                    BackupAedId: aedsCheck?.backup_ids,
                  },
                });
              } else if (Number(aedsCheck?.backup) === 1) {
                handleAedDispatch();
                navigate(
                  `/account/aed-details/check-select?aed_id=${aedsCheck?.backup_ids[0]}&account_id=${aedsCheck?.backupaccount_id}`
                );
              }
            }}
          >
            {aedsCheck?.backup}{" "}
          </h3>
          <h6 className="mt-2 mb-2 text-center">Backup</h6>
        </div>
      </div>
    </div>
  );
};

export default AedCheckCard;
