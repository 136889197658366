import { AccessTimeOutlined } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";

const CustomTimePicker = ({
  handleTimeChanges,
  formData,
  setFormData,
  fieldName,
  disabled,
  setError,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState("");
  const [selectedMinute, setSelectedMinute] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState("");
  const dropdownRef = useRef(null);

  const timeValue = formData[fieldName] ||  "";

  // const hours = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));
  const hours = Array.from({ length: 12 }, (_, i) => ((i + 12) % 12 || 12).toString().padStart(2, '0'));
  const minutes = Array.from({ length: 60 }, (_, i) => i < 10 ? `0${i}` : i.toString()); // Minutes 00-59
  const periods = ["AM", "PM"];

  const handleBlur = () => {
    const regex = /^(0?[0-9]|1[0-2]):([0-5][0-9]) ?([AaPp][Mm])$/;
    const timeValue = formData[fieldName]?.trim();
   
    if (timeValue?.length > 0 && !regex.test(timeValue)) {
      setError("Invalid time format");
      setFormData({
        ...formData,
        [fieldName]: "",
      });
    } else {
      setError("");
    }
  };

  const toggleDropdown = () => {
    if (!disabled) {
      setDropdownOpen(!dropdownOpen);
    }
  };

  const handleHourSelect = (hour) => {
    setSelectedHour(hour);
    updateTime(hour, selectedMinute, selectedPeriod);
  };

  const handleMinuteSelect = (minute) => {
    setSelectedMinute(minute);
    updateTime(selectedHour, minute, selectedPeriod);
  };

  const handlePeriodSelect = (period) => {
    setSelectedPeriod(period);
    updateTime(selectedHour, selectedMinute, period);
  };
  const updateTime = (newHour, newMinute, newPeriod) => {
    const currentTime = formData[fieldName]?.split(' ') || [];
    const [currentHourMinute, currentPeriod] = currentTime;
    const [currentHour, currentMinute] = currentHourMinute ? currentHourMinute.split(':') : [];
  
    const hour = newHour || currentHour || '--'; 
    const minute = newMinute || currentMinute || '--'; 
    const period = newPeriod || currentPeriod || ''; 
  
    const time = `${hour}:${minute} ${period}`;
    
    setFormData({
      ...formData,
      [fieldName]: time,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleClickOutside);
    };
  }, []);

  // const printTimeValue = (val) => {
  //   if(val === 'closed' || val === 'Closed') {
  //     return '';  
  //   }
  //   return val;
  // }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }} >
        <div
          ref={dropdownRef}
          style={{
            display: "flex",
            justifyContent: "center",
            backgroundColor: disabled ? "#e9ecef" :"white",
            alignItems: "center",
            width: "155px",
            borderRadius: "5px",
            paddingRight: "10px",
            position: "relative",
          }}
        >
          <input
            type="text"
            placeholder="hh:mm am/pm"
            value={!disabled ? timeValue || "" : ""}
            onChange={handleTimeChanges}
            onBlur={handleBlur}
            maxLength={8}
            disabled={disabled}
          />

          {!disabled && (
            <AccessTimeOutlined
              onClick={toggleDropdown}
              style={{ fontSize: 17, cursor: "pointer" }}
            />
          )}

          {dropdownOpen && (
            <div
              style={{
                position: "absolute",
                top: "100%",
                left: 0,
                backgroundColor: "white",
                border: "1px solid #ccc",
                borderRadius: "4px",
                zIndex: 1000,
                display: "flex",
                flexDirection: "row",
                padding: "5px",
                maxHeight: "200px",
              }}
            >
              <div
                style={{
                  marginRight: "5px",
                  maxHeight: "200px",
                  overflowY: "auto",
                  scrollbarWidth: "none", // For Firefox
                  msOverflowStyle: "none" // For Internet Explorer and Edge
                }}
              >
                {hours.map((hour) => (
                  <div
                    key={hour}
                    onClick={() => handleHourSelect(hour)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      transition: "background-color 0.3s", 
                      marginRight: "5px"
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#e0f7fa")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
                  >
                    {hour}
                  </div>
                ))}
              </div>
              <div
                style={{
                  marginRight: "5px",
                  maxHeight: "200px",
                  overflowY: "auto",
                  scrollbarWidth: "none", // For Firefox
                  msOverflowStyle: "none" // For Internet Explorer and Edge
                }}
              >
                {minutes.map((minute) => (
                  <div
                    key={minute}
                    onClick={() => handleMinuteSelect(minute)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                      marginRight: "5px"
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#e0f7fa")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
                  >
                    {minute}
                  </div>
                ))}
              </div>
              <div>
                {periods.map((period) => (
                  <div
                    key={period}
                    onClick={() => handlePeriodSelect(period)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#e0f7fa")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "white")}
                  >
                    {period}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* {error && <span style={{ color: "red" }}>{error}</span>} */}
      </div>
    </>
  );
};

export default CustomTimePicker;
