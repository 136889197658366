import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { fetchAedTechBarGraphData, fetchContact } from "../Services";
import GraphCommonDatePicker from "./GraphCommonDatePicker";
import { FormatDate } from "../../../../common/helper";

const LineGraph = ({ sections }) => {
  console.log("Hii");
  const getCurrentDate = () => {
    const today = new Date();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const year = today.getFullYear();
    return `${month}/${day}/${year}`;
  };

  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(getCurrentDate());
  const [endDate, setEndDate] = useState();
  const [selectedTech, setSelectedTech] = useState("all");
  const [contact, setContact] = useState([]);
  const [pieData, setPiedata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Format date for X-axis
  const formatXAxisDate = (dateStr) => {
    const date = moment(dateStr, "MM/DD/YYYY");
    return date.format("MMM D"); // This will format as "Jan 5", "Jan 6", etc.
  };

  // Transform pieData into format suitable for LineChart
  const transformedData = React.useMemo(() => {
    const groupedByDay = {};

    Object.entries(pieData).forEach(([date, items]) => {
      if (!Array.isArray(items)) return;

      // Format date using moment for consistency
      const formattedDate = moment(date).format("MM/DD/YYYY");

      if (!groupedByDay[formattedDate]) {
        groupedByDay[formattedDate] = {};
      }

      items.forEach((item) => {
        if (item.technician_name && item.aed_count) {
          groupedByDay[formattedDate][item.technician_name] =
            (groupedByDay[formattedDate][item.technician_name] || 0) +
            parseInt(item.aed_count);
        }
      });
    });

    // Convert to array and sort by date
    return Object.keys(groupedByDay)
      .sort((a, b) => moment(a, "MM/DD/YYYY").diff(moment(b, "MM/DD/YYYY")))
      .map((date) => ({
        date,
        formattedDate: formatXAxisDate(date), // Add formatted date for display
        ...groupedByDay[date],
      }));
  }, [pieData]);

  // Custom tooltip to show formatted date
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        >
          <p className="label">{formatXAxisDate(label)}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color, margin: "5px 0" }}>
              {entry.name}: {entry.value}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const convertDateFormat = (dateString) => {
    if (!dateString) {
      console.error("Date string is undefined or empty");
      return "";
    }

    const [month, day, year] = dateString.split("/");
    return `${year}-${month}-${day}`;
  };

  const fetchData = async () => {
    try {
      const result = await fetchContact();
      setContact(result);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const payload = {
    contact_ids: selectedTech === "all" ? "" : selectedTech,
    start_date: convertDateFormat(startDate),
    end_date: convertDateFormat(endDate),
    key: selectedTech === "all" ? "all" : "",
  };

  const fetchGraphData = async () => {
    console.log("aed");
    try {
      const result = await fetchAedTechBarGraphData(
        sections,
        "AedTechServicing",
        "AedTechServicing",
        payload
      );
      console.log({ result });
      setPiedata(result || []);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    fetchGraphData();
  }, []);

  useEffect(() => {
    fetchGraphData();
  }, [dispatch, startDate, endDate, selectedTech]);

  const handleDateChange = (date, val) => {
    const formattedDate = date ? FormatDate(val) : "";
    if (date === "startDate") {
      setStartDate(formattedDate);
    }
    if (date === "endDate") {
      setEndDate(formattedDate);
    }
  };

  const colors = [
    "#8884d8",
    "#82ca9d",
    "#ffc658",
    "#ff7300",
    "#a4de6c",
    "#d0ed57",
    "#ffc658",
    "#8dd1e1",
    "#a4de6c",
    "#d0ed57",
  ];

  const handleTechChange = (e) => {
    setSelectedTech(e.target.value);
  };

  const technicianNames = React.useMemo(() => {
    const names = new Set();
    Object.values(pieData).forEach((dayData) => {
      if (Array.isArray(dayData)) {
        dayData.forEach((item) => {
          if (item.technician_name) {
            names.add(item.technician_name);
          }
        });
      }
    });
    return Array.from(names);
  }, [pieData]);

  return (
    <div
      className="col"
      style={{
        marginTop: "10px",
        padding: "10px",
        backgroundColor: "transparent",
        marginBottom: "20px",
        border: "2px solid black",
        alignItems: "center",
      }}
    >
      <h2>AED Tech Servicing Check Completed</h2>

      <div className="row">
        <div className="col-12 mt-3">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={transformedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="date"
                tickFormatter={formatXAxisDate}
                interval="preserveStartEnd"
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              {technicianNames.map((techName, index) => (
                <Line
                  key={techName}
                  type="monotone"
                  dataKey={techName}
                  stroke={colors[index % colors.length]}
                  activeDot={{ r: 8 }}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="row justify-content-center mt-3">
        <div className="col-auto d-flex align-items-center">
          <GraphCommonDatePicker
            calName="startDate"
            CalVal={startDate}
            HandleChange={(date, val) => handleDateChange(date, val)}
          />
          <span className="mx-2">-</span>
          <GraphCommonDatePicker
            calName="endDate"
            CalVal={endDate}
            HandleChange={(date, val) => handleDateChange(date, val)}
            otherDate={startDate}
            isEndDate={true}
          />
        </div>
      </div>

      <div className="row justify-content-center mt-3">
        <div className="col-auto">
          <select
            className="form-select w-auto"
            value={selectedTech}
            onChange={handleTechChange}
            style={{ fontSize: "16px", fontWeight: "bold" }}
          >
            <option value="all">All</option>
            {contact &&
              contact?.map((contact) => (
                <option key={contact.contact_id} value={contact.contact_id}>
                  {contact.contact_name}
                </option>
              ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default LineGraph;
