import React, { useState } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import PermissionManagerMenu from "../../../domains/permissionManager/pages/PermissionManagerMenu";

const Sidebar = ({ show, setShow, handleClose }) => {
  const [openDropdown, setOpenDropdown] = useState(null);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        style={{ width: "300px", background: "#000" }}
        id="sidebar-menus"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-white">Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100%",
            }}
          >
            <div>
              <Nav className="flex-column">
                <Link
                  to="/dashboard"
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                >
                  Dashboard
                </Link>
                <Link
                  to="/accounts-listing"
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                >
                  Accounts
                </Link>
                <Link to="/Admin/Sites-listing" className="nav-link">
                  Site Listing
                </Link>
                <Link to="/Admin/Contact-listing" className="nav-link">
                  Contact Listing
                </Link>
                <Link to="/Admin/accessory-listing" className="nav-link">
                  Accessory Listing
                </Link>
                <Link to="/Admin/Aed-listing" className="nav-link">
                  AED Listing
                </Link>
                <Link to="/account/support-listing" className="nav-link">
                  Support Listing
                </Link>
                <Link to="/Admin/Note-listing" className="nav-link">
                  Notes Listing
                </Link>
              </Nav>
              <button to="" className="nav-link">
                <PermissionManagerMenu
                  setOpenDropdown={setOpenDropdown}
                  show={show}
                  setShow={setShow}
                />
              </button>
            </div>

            <div
              className="text-white p-1"
              style={{
                fontSize: "0.875rem",
                borderTop: "1px solid rgba(255, 255, 255, 0.1)",
                boxShadow: "inset 0 1px 0 rgba(255, 255, 255, 0.1)",
                cursor: "default",
                hover: "none",
              }}
            >
              version 1.0.0
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
