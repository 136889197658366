import React, { useEffect, useState } from "react";
import { Button as FixedButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { useDispatch, useSelector } from "react-redux";
import SubHeading from "../../dashboard/components/header/SubHeading";
import AccessoryListingTbl from "../components/Listing/AccessoryListingTbl";
import {
  removeFilterData,
  removePayloadData,
} from "../../../store/slices/AccessoryListingFilterSlice";
import { CallPOSTAPI } from "../../../common/services";
import AccessoryListingFilter from "../components/Listing/AccessoryListingFilter";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import { CancleIcon, CheckIcon } from "../../../common/helper/icons";
import { DecryptToken, FormatDate } from "../../../common/helper";
import moment from "moment";
import { truncateText } from "../../../common/helper/BasicFn";
import ScrollTopButton from "../../../common/components/ScrollTopButton";

const AccessoryListing = ({ setShowSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = DecryptToken();
  const [showLoading, setShowLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [aedList, setAedList] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const accessoryFilterData = useSelector(
    (state) => state.accessorylistingfilter.accessoryListingFilterData
  );
  const accessoryPayloadData = useSelector(
    (state) => state.accessorylistingfilter.accessoryListingPayloadData
  );

  const getAeds = async () => {
    setShowLoading(true);
    let result;
    if (accessoryFilterData && Object.keys(accessoryFilterData).length !== 0) {
      result = await CallPOSTAPI("acc/accessorylisting-search", {
        ...accessoryPayloadData,
        page: 1,
        search: search.trimEnd(),
      });
    } else {
      result = await CallPOSTAPI("acc/accessory-listing", {
        page: currentPage,
        search: search.trimEnd(),
      });
    }

    if (result?.data?.status) {
      const aeds = result?.data?.data;
      setAedList(aeds?.accList);
      setTotalPage(aeds?.totalPages);
    }

    setShowLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // on load fetch data
  useEffect(() => {
    getAeds();
  }, [search, currentPage]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClearFilterData = async () => {
    dispatch(removeFilterData());
    dispatch(removePayloadData());
    setShowLoading(true);
    const result = await CallPOSTAPI("acc/accessory-listing", {
      page: 1,
      search: search.trimEnd(),
    });
    if (result?.data?.status) {
      const aeds = result?.data?.data;
      setAedList(aeds?.accList);
      setTotalPage(aeds?.totalPages);
    }
    setShowLoading(false);
  };

  //Render Functions:
  const renderDate = (item) => {
    return (
      <>
        <img
          className="image"
          src={
            item.hasOwnProperty("manufactured_date")
              ? item?.manufactured_date
                ? "/assets/images/BatteryMfgr.svg"
                : item?.battery_expiration
                ? "/assets/images/Battery.png"
                : "/assets/images/Installby.svg"
              : item?.pad_expiration && item?.is_pediatric === 1
              ? "/assets/images/child-Vector.png"
              : "/assets/images/people-Group.svg"
          }
          style={{
            width: item?.battery_expiration ? 15 : 25,
            height: item?.battery_expiration ? 20 : 20,
            marginRight: "2%",
          }}
        />
        {item.hasOwnProperty("manufactured_date")
          ? item?.manufactured_date
            ? FormatDate(item?.manufactured_date)
            : item?.battery_expiration
            ? FormatDate(item?.battery_expiration)
            : item?.install_date
            ? FormatDate(item?.install_date)
            : "N/A"
          : item?.pad_expiration
          ? FormatDate(item?.pad_expiration)
          : "N/A"}
        <div style={{ margin: "5% 0" }} />
        {item?.section_name === "charge_pack" && item?.pad_expiration_1 ? (
          <img
            className="image"
            src="/assets/images/people-Group.svg"
            style={{ marginRight: "2%" }}
          />
        ) : null}
        {item.section_name === "charge_pack" && item?.pad_expiration_1
          ? moment(item.pad_expiration_1).format("MM/DD/YYYY")
          : null}

        <div style={{ margin: "5% 0" }} />
        {item?.section_name === "charge_pack" && item?.pad_expiration_2 ? (
          <img
            className="image"
            src="/assets/images/people-Group.svg"
            style={{ marginRight: "2%" }}
          />
        ) : null}
        {item.section_name === "charge_pack" && item?.pad_expiration_2
          ? moment(item.pad_expiration_2).format("MM/DD/YYYY")
          : null}
      </>
    );
  };
  const headerColumns = [
    {
      Header: "Battery Part",
      accessor: "battery_part",
      width: "12%",
      Cell: ({ row }) => {
        const batteryPart =
          row.original.battery_part || row.original.pad_part || "N/A"; // Alternate key and fallback to "N/A"
        return batteryPart;
      },
    },

    {
      Header: "Accessory Type",
      accessor: "accessory_type",
      width: "15%",
    },
    {
      Header: "Date",
      accessor: "Manufactured Date",
      width: "15%",
      Cell: ({ row }) => renderDate(row.original),
    },
    {
      Header: "Lot",
      accessor: "battery_lot",
      Cell: ({ row, data }) => (
        <div className="wrap-cell">
          {row.original.hasOwnProperty("manufactured_date")
            ? row.original?.battery_lot || "N/A"
            : row.original?.pad_lot || "N/A"}
        </div>
      ),
    },
    {
      Header: "UDI",
      accessor: "battery_udi",
      Cell: ({ row }) => {
        return row.original.hasOwnProperty("manufactured_date")
          ? row.original?.section_name === "charge_pack"
            ? row.original?.charge_pak_uid || "N/A"
            : row.original?.battery_udi || "N/A"
          : row.original?.pad_udi || "N/A";
      },
    },
    {
      Header: "DNI",
      accessor: "dni",
      disableSortBy: true,
      Cell: ({ row }) => (
        <span>{row.original.dni ? <CheckIcon /> : <CancleIcon />}</span>
      ),
    },

    {
      Header: "Account",
      accessor: "account_name",
      width: "16%",
      Cell: ({ row }) => (
        <span
          title={
            row.original.account_name.length > 25
              ? row.original.account_name
              : undefined // Tooltip only if the text is truncated
          }
        >
          {truncateText(row.original.account_name, 25)}
        </span>
      ),
    },
    {
      Header: "Site",
      accessor: "site_name",
      width: "16%",
      Cell: ({ row }) => (
        <span
          title={
            row?.original?.site_name?.length > 25
              ? row?.original?.site_name
              : undefined // Tooltip only if the text is truncated
          }
        >
          {" "}
          {truncateText(row?.original?.site_name, 25)}
        </span>
      ),
    },
  ];

  function hasValues(obj) {
    // Iterate through each key-value pair in the object
    for (let key in obj) {
      // Check if the value is an array and not empty
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        return true; // There is a non-empty array
      }
      // Check if the value is a string and not empty
      if (typeof obj[key] === "string" && obj[key].trim() !== "") {
        return true; // There is a non-empty string
      }
    }
    // If no non-empty value is found, return false
    return false;
  }

  return (
    <>
      <div>
        {/* accessoryFilterData &&
          Object.keys(accessoryFilterData).length !== 0 ? (
          <>
            <FixedButton
              className="btn-style-accessory-cancel-filter"
              onClick={handleClearFilterData}
            >
              Clear Filter
            </FixedButton>
          </>
        ) : null */}

        {hasValues(accessoryFilterData) ? (
          <FixedButton
            className="btn-style-accessory-cancel-filter"
            onClick={handleClearFilterData}
          >
            Clear Filter
          </FixedButton>
        ) : (
          ""
        )}

        {Number(user?.user_type) === 0 ? (
          <FixedButton className="btn-style-filter" onClick={handleDrawerOpen}>
            Accessory Filters
          </FixedButton>
        ) : (
          ""
        )}

        <Drawer
          sx={{
            width: "400px",
            flexShrink: 0,
            // background:'#000',
            "& .MuiDrawer-paper": {
              background: "#000",
              width: "400px",
              boxSizing: "border-box",
              overflowY: "auto",
            },
          }}
          anchor="right"
          open={open}
          onClose={handleDrawerClose}
        >
          {/* filter component  */}
          <AccessoryListingFilter
            setAedList={setAedList}
            setOpen={setOpen}
            setShowLoading={setShowLoading}
            search={search}
            setTotalPage={setTotalPage}
          />
        </Drawer>
      </div>
      <div className="mt-4">
        <SubHeading
          hideNew={true}
          hideHierarchy={true}
          title={"Accessory Listing"}
          subHeading={true}
        />
        <div>
          {/* <AccessoryListingTbl
            standaloneData={aedList}
            setStandAloneData={setAedList}
            search={search}
            setSearch={setSearch}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
            setTotalPage={setTotalPage}
            totalPage={totalPage}
            setShowLoading={setShowLoading}
            showLoading={showLoading}
            setCurrentPage={setCurrentPage}
          /> */}
          <NewGlobalTable
            isSearchable={true}
            isCache={true}
            pageSize={50}
            payloadData={
              accessoryPayloadData &&
              Object.keys(accessoryPayloadData).length > 0
                ? accessoryPayloadData
                : null
            }
            apiEndPoint={
              Number(user?.user_type) === 0
                ? accessoryPayloadData &&
                  Object.keys(accessoryPayloadData).length > 0
                  ? "acc/accessorylisting-search"
                  : "acc/accessory-listing"
                : "user/user-accessory-listing"
            }
            keyAPiName={Number(user?.user_type) === 0 ? "accList" : "Acc"}
            headerColumns={headerColumns}
          />
        </div>
      </div>

     {/* Top to page button*/}
     <ScrollTopButton />

    </>
  );
};

export default AccessoryListing;
