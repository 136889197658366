import React, { useState, useEffect, useMemo } from "react";
import { Box, Skeleton } from "@mui/material";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { useNavigate } from "react-router-dom";
import { CallGETAPI } from "../../../../../common/services";
import Moment from "react-moment";
import moment from "moment";
import { isSubAdminPermission } from "../../../../../common/helper/permission";
import check from "../../../../../common/img/Check.svg";
import cancel from "../../../../../common/img/Cancel.svg";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import AEDChecksTableSkeleton from "../AedSkeletons/AEDChecksTableSkeleton";

import NewGlobalTable from "../../../../../common/components/custom-ui/NewGlobalTable";
import { truncateText } from "../../../../../common/helper/BasicFn";
import { useDispatch } from "react-redux";
import { addNewBreadcrumbs } from "../../../../../store/slices/breadcrumbsSlice";
import { isPermission } from "../../../../../common/helper/PermissionManager";

export default function AedServicing({ stateData, privileges, is_user }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [aedServiceData, setAEDServiceData] = useState([]);
  const [aedId, setAEDId] = useState();
  const [alarmBattery, setAlarmBatteryDate] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "",
  };
  console.log({ stateData })
  const handleDispatch = (item, aedId, questions_id) => {
    BreadCrumbsObject.title = "Aed Servicing Details";
    BreadCrumbsObject.tab = item;
    BreadCrumbsObject.type = "AED Servicing ";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account-details/AEDServiceDetails/${stateData.aedId}/${questions_id}`,
      })
    );
  };
  const headerColumns = [
    {
      Header: "Aed Service Date",
      accessor: "inspection_date",
      Cell: ({ value, row }) => (
        <span
          className={
            Number(isPermission({ type: "link", name: "aedServiceDate" })) === 1
              ? "link"
              : ""
          }
          style={{
            cursor:
              Number(isPermission({ type: "link", name: "aedServiceDate" })) ===
                1
                ? "pointer"
                : "",
            color: "#0c71c3",
            textDecoration: "none",
          }}
          onClick={() => {
            if (
              Number(isPermission({ type: "link", name: "aedServiceDate" })) ===
              1
            ) {
              handleDispatch(aedId, row.original.questions_id);
              navigate(
                `/account-details/AEDServiceDetails/${stateData.aedId}/${row.original.questions_id}`
              );
            }
          }}
        >
          <Moment date={value} format="MM/DD/YYYY h:mm:ss" />
        </span>
      ),
    },
    {
      Header: "Present/Ready",
      accessor: "aeds_ready_status",
      Cell: ({ value }) => (
        <img src={value ? check : cancel} alt="Status" height={12} />
      ),
    },
    {
      Header: "Replaced Rescue Kit",
      accessor: "rescue_kits_status",
      Cell: ({ value }) => (
        <img src={value ? check : cancel} alt="Status" height={12} />
      ),
    },
    {
      Header: "Replaced Accessories",
      accessor: "accessories_status",
      Cell: ({ value }) => (
        <img src={value ? check : cancel} alt="Status" height={12} />
      ),
    },
    {
      Header: "Alarm Battery Exp",
      Cell: () =>
        moment(alarmBattery).isValid()
          ? moment(alarmBattery).format("MM/DD/YYYY")
          : "",
    },
    {
      Header: "Technician",
      accessor: "inspection_by",
      width: "15%",
      Cell: ({ row }) => truncateText(row.original.inspection_by, 20),
    },
  ];

  const handleDataFetched = async (data) => {
    if (data) {
      setAEDId(data.aed_id || null);
      setAlarmBatteryDate(data.alarmBattery || null);
      // console.log(">>>>", data.aed_id);
      // console.log(">>>>", data.alarmBattery);
      return data || [];
    }
  };

  // const fetchLoad = async () => {
  //   setLoading(true);
  //   const res = await CallGETAPI(
  //     `aed/check_service_history_data_by_id/${stateData.aedId}?page=${currentPage}&pageSize=10`
  //   );

  //   if (res?.data?.data?.aed_id) {
  //     setAEDId(res?.data?.data.aed_id);
  //     setAEDServiceData(res?.data?.data?.serviceQuestion);
  //     setAlarmBatteryDate(res?.data?.data?.alarmBattery);
  //     setTotalPages(res?.data?.data?.totalPages);
  //   }
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   fetchLoad();
  // }, []);

  // const columns = useMemo(
  //   () => [
  //     {
  //       Header: "Aed Service Date",
  //       accessor: "inspection_date",
  //       Cell: ({ value, row }) => (
  //         <span
  //           className={
  //             isSubAdminPermission("service-check-details") === 1 ? "link" : ""
  //           }
  //           style={{
  //             cursor: "pointer",
  //             color: "#0c71c3",
  //             textDecoration: "none",
  //           }}
  //           onClick={() =>
  //             isSubAdminPermission("service-check-details") === 1 &&
  //             navigate(
  //               `/account-details/AEDServiceDetails/${aedId}/${row.original.questions_id}`
  //             )
  //           }
  //         >
  //           <Moment date={value} format="MM/DD/YYYY h:mm:ss" />
  //         </span>
  //       ),
  //     },
  //     {
  //       Header: "Present/Ready",
  //       accessor: "aeds_ready_status",
  //       Cell: ({ value }) => (
  //         <img src={value ? check : cancel} alt="Status" height={12} />
  //       ),
  //     },
  //     {
  //       Header: "Replaced Rescue Kit",
  //       accessor: "rescue_kits_status",
  //       Cell: ({ value }) => (
  //         <img src={value ? check : cancel} alt="Status" height={12} />
  //       ),
  //     },
  //     {
  //       Header: "Replaced Accessories",
  //       accessor: "accessories_status",
  //       Cell: ({ value }) => (
  //         <img src={value ? check : cancel} alt="Status" height={12} />
  //       ),
  //     },
  //     {
  //       Header: "Alarm Battery Exp",
  //       Cell: () =>
  //         moment(alarmBattery).isValid()
  //           ? moment(alarmBattery).format("MM/DD/YYYY")
  //           : "",
  //     },
  //     {
  //       Header: "Technician",
  //       accessor: "inspection_by",
  //     },
  //   ],
  //   [aedId, alarmBattery, navigate]
  // );

  // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, page } =
  //   useTable(
  //     {
  //       columns,
  //       data: aedServiceData || [],

  //       initialState: { pageIndex: 0, pageSize: 50 },
  //     },
  //     useGlobalFilter,
  //     useSortBy,
  //     usePagination
  //   );

  //   const handlePageChange = (newPage) => {
  //     setCurrentPage(newPage);
  //   };

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box className="text-left">
          <h4 className="heading">Aed Service History</h4>
        </Box>
      </div>
      <div className="table-responsive">
        {loading ? (
          <AEDChecksTableSkeleton columns={columns} />
        ) : (
          <table
            {...getTableProps()}
            className="table table-bordered table-hover"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{ backgroundColor: "#999" }}
                      className="tb-td"
                    >
                      <div
                        className="d-flex align-items-center th-d"
                        style={{ cursor: "pointer" }}
                      >
                        {column.render("Header")}
                        <span className="ml-1">
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <SouthIcon size={2} />
                            ) : (
                              <NorthIcon size={2} />
                            )
                          ) : (
                            <SouthIcon size={2} />
                          )}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {aedServiceData?.length === 0 ? (
                <tr>
                  <td
                    colSpan={columns.length}
                    style={{
                      textAlign: "center",
                      padding: "20px",
                      fontStyle: "italic",
                      color: "grey",
                    }}
                  >
                    No data found
                  </td>
                </tr>
              ) : (
                rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      style={{
                        backgroundColor: i % 2 === 0 ? "white" : "#e4e4e4",
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        )}
      </div>
      </div>
      {totalPages > 1 && (
        <div className="mt-4 d-flex justify-content-end align-items-center">
          <nav aria-label="Page navigation">
            <ul className="pagination">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                  style={{background: "#dad7d763"}}
                >
                  &lt;
                </button>
              </li>
              {currentPage > 3 && (
                <>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageChange(1)}
                    >
                      1
                    </button>
                  </li>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                </>
              )}
              {[...Array(5)].map((_, idx) => {
                const pageNumber = currentPage - 2 + idx;
                return pageNumber > 0 && pageNumber <= totalPages ? (
                  <li key={pageNumber} className="page-item">
                    <button
                      className={`btn mx-1 ${
                        currentPage === pageNumber ? "btn-primary" : "btn-light"
                      }`}
                      onClick={() => handlePageChange(pageNumber)}
                    >
                      {pageNumber}
                    </button>
                  </li>
                ) : null;
              })}
              {currentPage < totalPages - 2 && (
                <>
                  <li className="page-item">
                    <span className="mx-1">...</span>
                  </li>
                  <li className="page-item">
                    <button
                      className="btn btn-light mx-1"
                      onClick={() => handlePageChange(totalPages)}
                    >
                      {totalPages}
                    </button>
                  </li>
                </>
              )}
              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="btn btn-light mx-1"
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  style={{background: "#dad7d763"}}
                >
                  &gt;
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )} */}

      {/* New Table Implementation: */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box className="text-left">
          <h4 className="heading">Aed Service History</h4>
        </Box>
      </div>
      <NewGlobalTable
        isCache={true}
        pageSize={10}
        apiEndPoint={`aed/check_service_history_data_by_id/${stateData.aedId}`}
        keyAPiName={"serviceQuestion"}
        method="GET"
        headerColumns={headerColumns}
        customRenderData={handleDataFetched}
      />
    </>
  );
}
