import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import {
  Form,
  Button as BButton,
  Button as BsButton,
  InputGroup,
  Button,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { CallGETAPI, CallPOSTAPI } from "../../../common/services";
import CustomToggleButton2 from "../../../common/components/toggleSwitch/CustomToggle2";
import PaginateAutoComplete from "../../../common/components/custom-ui/PaginateAutoComplete";
import { sortArrAscending } from "../../../common/helper/constants";

const EditAssignCreateModal = ({
  show,
  setShow,
  rowData,
  fetchCreateTblData,
}) => {
  const [sortedNewRoleDataArr, setSortedNewRoleDataArr] = useState([]);
  const [filteredPermissionsArray, setfilteredPermissionsArray] = useState([]);
  const [permissionsValidations, setPermisionsValidation] = useState(false);
  const [roleNameValidations, setRoleNameValidations] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    role_name: rowData?.role_name,
    permissions: filteredPermissionsArray,
    is_admin_role: rowData?.is_admin_role,
    role_type: rowData?.role_type || "",
  });

  const fetchData = async () => {
    try {
      const res = await CallGETAPI(
        "permission/get-permission?page=1&pageSize=100"
      );
      const roleDataV = res.data.data;

      const nrda = roleDataV?.permissions?.map((obj) => ({
        label: obj.permissions,
        value: obj.pr_id,
      }));
      let sortedArr = sortArrAscending(nrda, "label");
      setSortedNewRoleDataArr(sortedArr);
    } catch (error) {
      console.error("Error fetching role data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const initialPermisisonsValuesArr = rowData.permission_group_id
      .split(",")
      .map(Number);
    const filteredPermissionsArray = sortedNewRoleDataArr.filter((item) =>
      initialPermisisonsValuesArr.includes(item.value)
    );
    setfilteredPermissionsArray(filteredPermissionsArray);
  }, [sortedNewRoleDataArr]);

  useEffect(() => {
    setFormData({
      role_name: rowData?.role_name,
      permissions: filteredPermissionsArray,
      is_admin_role: rowData?.is_admin_role,
      role_type: rowData?.role_type || "",
    });
  }, [filteredPermissionsArray, rowData]);

  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: value,
    }));
  };

  const [isInvalid, setIsInvalid] = useState({
    role_name: false,
    permissions: false,
    role_type: false,
  });

  const handleModalSubmit = async (e) => {
    e.preventDefault();

    let is_false = 0;
    let obj = {
      role_name: false,
      permissions: false,
    };

    if (formData.role_name.trim() === "") {
      obj.role_name = true;
      is_false = 1;
    }

    if (formData.permissions.length === 0) {
      obj.permissions = true;
      is_false = 1;
    }

    if (formData.role_type.trim() === "") {
      obj.role_type = true;
      is_false = 1;
    }

    if (is_false) {
      setIsInvalid(obj);
      return "";
    }

    setLoading(true);

    if (formData.role_name === "") {
      // setValidated(true);
      setRoleNameValidations(true);
      return;
    }

    if (formData?.permissions === "") {
      console.log("hii");
      setPermisionsValidation(true);
      return;
    }
    let permissionArr = formData.permissions;
    let finalPermissionsArr = permissionArr.map(
      (permission) => permission.value
    );
    let body = { ...formData, permissions: finalPermissionsArr + "" };

    const res = await CallPOSTAPI(
      "permission/update-role/" + rowData?.role_id,
      body
    );
    if (res?.status) {
      toast.success("Role Updated Successfully");
      setShow(false);
      fetchCreateTblData();
      setFormData({ role_name: "", permissions: "" });
    }
  };

  const handlePermissionsChange = (e) => {
    setFormData({
      ...formData,
      permissions: e.target.formatted,
    });
    setIsInvalid({ ...isInvalid, permissions: false });
  };

  const handleToggleChange = () => {
    if (formData.is_admin_role) {
      setFormData({ ...formData, is_admin_role: 0 });
    } else {
      setFormData({ ...formData, is_admin_role: 1 });
    }
  };

  return (
    <>
      <Form
        className=""
        onSubmit={handleModalSubmit}
        noValidate
        validated={validated}
        id="create-new-equipment-form"
      >
        <Modal
          show={show}
          onHide={() => setShow(false)}
          size="xl"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Create Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="my-4"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "fit-content",
                  flexWrap: "wrap",
                  gap: "5%",
                }}
              >
                <Form.Group className={"col"} style={{ width: 350 }}>
                  <Form.Label>Enter Role Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="role_name"
                    value={formData.role_name}
                    onChange={(e) => {
                      handleChange(e, "role_name");
                      setIsInvalid({ ...isInvalid, role_name: false });
                      setRoleNameValidations(false);
                    }}
                    required
                  />
                  {isInvalid.role_name && (
                    <Form.Control.Feedback type="" className="text-danger mt-1">
                      Role name is required.
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className={"col"} style={{ width: 250 }}>
                  <Form.Label>Select Permissions*</Form.Label>
                  <PaginateAutoComplete
                    dropDownName={"permissions"}
                    apiEndpoint="permission/get-permission"
                    idKey={"pr_id"}
                    valueKey={"permissions"}
                    parentKey={"permissions"}
                    onSelect={(e, value) => {
                      handlePermissionsChange(e);
                      setIsInvalid({ ...isInvalid, permissions: false });
                    }}
                    shouldFetchOnOpen={false}
                    isCache={true}
                    className={"react-select-container"}
                    selectDisabled={false}
                    closeMenuSelect={false}
                    multiple={true}
                    showCheckBox={true}
                    showCountsOnly={1}
                    pageCount={20}
                    defaultValue={
                      formData?.permissions?.length > 0
                        ? formData.permissions?.map((it) => ({
                            pr_id: it.value,
                            permissions: it.label,
                          }))
                        : null
                    }
                    getResults={(res) => {
                      const result = res?.data?.data?.permissions || [];
                      const totalCountsVar = res?.data?.data?.totalCount || [];
                      return [result, totalCountsVar];
                    }}
                  />

                  {isInvalid.permissions && (
                    <Form.Control.Feedback type="" className="text-danger mt-1">
                      Permissions are required.
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <Form.Group className={"col"} style={{ width: 200 }}>
                  <Form.Label>Select Role Type*</Form.Label>
                  <select
                    className="form-control"
                    value={formData?.role_type}
                    name="role_type"
                    placeholder="Enter Role Type"
                    onChange={(e) => {
                      handleChange(e, "role_type");
                      setIsInvalid({ ...isInvalid, role_type: false });
                    }}
                    disabled={formData?.role_type != "" ? true : false}
                  >
                    <option value="">--Select One--</option>
                    <option value="account_level">Account Level</option>
                    <option value="site_level">Site Level</option>
                    <option value="enhancement">Enhancement</option>
                  </select>
                  {isInvalid?.role_type && (
                    <Form.Control.Feedback type="" className="text-danger mt-1">
                      Role type is required.
                    </Form.Control.Feedback>
                  )}
                </Form.Group>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Form.Label>Admin Role</Form.Label>
                  <CustomToggleButton2
                    ToggleName="admin_role_status"
                    ToggleValue={formData?.is_admin_role}
                    changeHandler={handleToggleChange}
                    is_read_only={false}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className=" d-flex justify-content-end">
              <button
                className="btn btn-danger text-uppercase ms-2"
                type="button"
                onClick={(e) => setShow(false)}
              >
                Cancel
              </button>
              &nbsp;
              <button
                className="btn btn-success text-uppercase ms-2"
                type="submit"
                onClick={handleModalSubmit}
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </Form>
    </>
  );
};

export default EditAssignCreateModal;
