import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CallPOSTAPI } from "../../../../common/services";
import GlobalDropdown from "../../../../common/components/custom-ui/GlobalDropdown";
import {
  addToHistoryStack,
  removeBreadcrumb,
} from "../../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";
import { Skeleton } from "@mui/material";
import { useSelector } from "react-redux";

function EditAssignAedTbl({
  showLoading,
  data,
  fromAed,
  accountId,
  redirectPath,
}) {
  const [editedData, setEditedData] = useState([]);
  const [newArray, setNewArray] = useState({
    equipments: [],
    unAssigned: [],
  });
  const breadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.breadcrumbs
  );
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const locationState = location.state;
  const dispatch = useDispatch();
  const { siteId } = useParams();
  const [previousValue, setPreviousValue] = useState([]);
  const [previousValueBackup1, setPreviousValueBackup1] = useState([]);
  const [previousValueBackup2, setPreviousValueBackup2] = useState([]);
  const [previousValueBackup3, setPreviousValueBackup3] = useState([]);

  // Function to find the index of aed_id in newArray
  function findAedIdIndex(newArray, editedData, rowIndex) {
    return newArray?.equipments?.findIndex(
      (item) => Number(item?.aed_id) === Number(editedData[rowIndex]?.aed_id)
    );
  }
  const handleDispatch = (siteId, is_active) => {
    if (is_active === 0) {
      const editPageUrl = "/account/assign-equipment/edit/" + siteId; // Replace this with your edit page's URL
      // console.log({ editPageUrl: editPageUrl })
      dispatch(removeBreadcrumb(editPageUrl));
    } else {
      const lastBreadcrumb =
        breadcrumbsFromStore[breadcrumbsFromStore.length - 1];
      const SecondLast = breadcrumbsFromStore[breadcrumbsFromStore.length - 2];
      dispatch(addToHistoryStack(SecondLast));
      dispatch(addToHistoryStack(lastBreadcrumb));
      const editPageUrl = "/account/assign-equipment/edit/" + siteId; // Replace this with your edit page's URL
      // console.log({ editPageUrl: editPageUrl })
      dispatch(removeBreadcrumb(editPageUrl));
    }
  };
  const handleDispatched = (accountId, siteId, isAccActive) => {
    let editPageUrl = "";
    if (isAccActive === 0) {
      editPageUrl = "/assign-quipment/" + accountId + "/" + siteId; // Replace this with your edit page's URL
    } else {
      editPageUrl = "/assign-quipment/" + accountId;
    }

    dispatch(removeBreadcrumb(editPageUrl));
  };

  const handlerDropDownChange = (e, selectedText, rowIndex, key, idKey) => {
    console.log({ e, selectedText, rowIndex, key, idKey });
    const indexResult = findAedIdIndex(newArray, editedData, rowIndex);
    let updatedEquipments = [...newArray.equipments]; // Copy the existing equipments array
    console.log("editedData[rowIndex]?.aed_id", editedData);
    const selectedEquipment = updatedEquipments[indexResult] || {
      aed_id: editedData[rowIndex]?.aed_id,

      users: [
        {
          type: "primary",
          primary: editedData[rowIndex]?.assign_user
            ? JSON.parse(editedData[rowIndex]?.assign_user)[0]?.primary
            : "",
          contact_id: editedData[rowIndex]?.assign_user
            ? JSON.parse(editedData[rowIndex]?.assign_user)[0]?.contact_id
            : "",
        },
        {
          type: "backup1",
          backup1: editedData[rowIndex]?.assign_user
            ? JSON.parse(editedData[rowIndex]?.assign_user)[1]?.backup1
            : "",
          contact_id: editedData[rowIndex]?.assign_user
            ? JSON.parse(editedData[rowIndex]?.assign_user)[1]?.contact_id
            : "",
        },
        {
          type: "backup2",
          backup2: editedData[rowIndex]?.assign_user
            ? JSON.parse(editedData[rowIndex]?.assign_user)[2]?.backup2
            : "",
          contact_id: editedData[rowIndex]?.assign_user
            ? JSON.parse(editedData[rowIndex]?.assign_user)[2]?.contact_id
            : "",
        },
        {
          type: "backup3",
          backup3: editedData[rowIndex]?.assign_user
            ? JSON.parse(editedData[rowIndex]?.assign_user)[3]?.backup3
            : "",
          contact_id: editedData[rowIndex]?.assign_user
            ? JSON.parse(editedData[rowIndex]?.assign_user)[3]?.contact_id
            : "",
        },
      ],
    };

    // Update the specific dropdown type based on key
    if (key === "primary") {
      selectedEquipment.users[0].primary =
        selectedText == "--Select One--" ? "" : selectedText;
      selectedEquipment.users[0].contact_id = idKey;
    } else if (key === "backup1") {
      selectedEquipment.users[1].backup1 =
        selectedText == "--Select One--" ? "" : selectedText;
      selectedEquipment.users[1].contact_id = idKey;
    } else if (key === "backup2") {
      selectedEquipment.users[2].backup2 =
        selectedText == "--Select One--" ? "" : selectedText;
      selectedEquipment.users[2].contact_id = idKey;
    } else if (key === "backup3") {
      selectedEquipment.users[3].backup3 =
        selectedText == "--Select One--" ? "" : selectedText;
      selectedEquipment.users[3].contact_id = idKey;
    }

    // Update equipment array
    if (indexResult !== -1) {
      updatedEquipments[indexResult] = selectedEquipment;
    } else {
      updatedEquipments.push(selectedEquipment);
    }
    const unassignedContact = [
      ...previousValue,
      ...previousValueBackup1,
      ...previousValueBackup2,
      ...previousValueBackup3,
    ];
    console.log({ unassignedContact });
    setNewArray({
      ...newArray,
      equipments: updatedEquipments,
      unAssigned: unassignedContact ? unassignedContact : [],
    });
  };

  useEffect(() => {
    if (data) {
      setEditedData(data);
    }
  }, [data]);

  const navigate = useNavigate();

  function processUsersData(data) {
    return {
      equipments: data?.equipments.map((item) => {
        const hasAnyData = item.users.some((user) => {
          const keys = Object.keys(user).filter((key) => key !== "type");
          return keys.some((key) => user[key]);
        });

        return {
          ...item,
          users: hasAnyData ? item.users : null,
        };
      }),
    };
  }

  const handleSubmit = async () => {
    setLoading(true);
    const processedData = processUsersData(newArray);
    const response = await CallPOSTAPI("aed/assign-aed-to-user", processedData);
    setLoading(false);
    if (response.data.status) {
      toast.success(response?.data?.msg);

      if (Number(fromAed) === 0) {
        const isAccActive = 1;
        handleDispatch(siteId);
        handleDispatched(locationState.accountId, siteId, isAccActive);
        if (redirectPath) {
          navigate("/" + redirectPath, {
            state: {
              tab: "Equipment",
            },
          });
        } else {
          navigate(-1);
        }
      } else {
        const isAccActive = 0;
        handleDispatch(siteId);
        handleDispatched(locationState.accountId, siteId, isAccActive);
        navigate(`/account/aed-details/${data[0]?.aed_id}`);
      }
    } else {
      toast.error(response?.data?.msg);
    }
  };

  return (
    <div className="mt-4 mb-5" style={{ width: "100%", paddingInline: "0px" }}>
      <div className="tableWrapper" id="table-main-20002">
        <table
          className="theme-table w-100 border-b-blue odd-even-row"
          style={{ width: "100vw", maxWidth: "100vw" }}
        >
          <thead className="heading">
            <tr className="border">
              <th
                className="border border-r-blue"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  maxWidth: "100px",
                  paddingLeft: "10px",
                }}
              >
                AED Brand / Model
              </th>
              <th
                className="border border-r-blue"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  maxWidth: "100px",
                  paddingLeft: "10px",
                }}
              >
                Serial #
              </th>
              <th
                className="border border-r-blue"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  maxWidth: "100px",
                  paddingLeft: "10px",
                }}
              >
                AED Placement
              </th>
              <th
                className="border border-r-blue"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  maxWidth: "100px",
                  paddingLeft: "10px",
                }}
              >
                Primary
              </th>
              <th
                className="border border-r-blue"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  maxWidth: "100px",
                  paddingLeft: "10px",
                }}
              >
                Backup 1
              </th>
              <th
                className="border border-r-blue"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  maxWidth: "100px",
                  paddingLeft: "10px",
                }}
              >
                Backup 2
              </th>
              <th
                className="border border-r-blue"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "14px",
                  maxWidth: "100px",
                  paddingLeft: "10px",
                }}
              >
                Backup 3
              </th>
            </tr>
          </thead>
          {showLoading ? (
            <>
              <tbody>
                {data?.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    <td style={{ maxWidth: "200px", paddingLeft: "10px" }}>
                      {showLoading && (
                        <Skeleton
                          count={5}
                          width={"90%"}
                          height={20}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      )}
                    </td>
                    <td style={{ maxWidth: "200px", paddingLeft: "10px" }}>
                      {showLoading && (
                        <Skeleton
                          count={5}
                          width={"90%"}
                          height={20}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      )}
                    </td>
                    <td style={{ maxWidth: "200px", paddingLeft: "10px" }}>
                      {showLoading && (
                        <Skeleton
                          count={5}
                          width={"90%"}
                          height={20}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      )}
                    </td>

                    <td style={{ maxWidth: "200px" }}>
                      {showLoading && (
                        <Skeleton
                          count={5}
                          width={"90%"}
                          height={20}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      )}
                    </td>
                    <td style={{ maxWidth: "200px" }}>
                      {showLoading && (
                        <Skeleton
                          count={5}
                          width={"90%"}
                          height={20}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      )}
                    </td>

                    <td style={{ maxWidth: "200px" }}>
                      {showLoading && (
                        <Skeleton
                          count={5}
                          width={"90%"}
                          height={20}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      )}
                    </td>

                    <td style={{ maxWidth: "200px" }}>
                      {showLoading && (
                        <Skeleton
                          count={5}
                          width={"90%"}
                          height={20}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          ) : (
            <>
              <tbody>
                {data?.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    <td style={{ maxWidth: "200px", paddingLeft: "10px" }}>
                      {showLoading ? (
                        <Skeleton
                          count={5}
                          width={"90%"}
                          height={20}
                          style={{ marginLeft: "2px", marginRight: "2px" }}
                        />
                      ) : (
                        item?.aed_brand
                      )}
                    </td>
                    <td style={{ maxWidth: "200px", paddingLeft: "10px" }}>
                      {item?.serial_number}
                    </td>
                    <td style={{ maxWidth: "200px", paddingLeft: "10px" }}>
                      {item?.placement}
                    </td>
                    <td style={{ maxWidth: "200px" }}>
                      <GlobalDropdown
                        dropDownName={"primary"}
                        apiEndpoint={`contact/acc-contact-dropdown/${
                          item?.account_id || accountId
                        }`}
                        idKey={"contact_id"}
                        valueKey={"contact_name"}
                        parentKey={"contact_list"}
                        onSelect={(e) => {
                          const selectedId = e.target.value;
                          const selectedText =
                            e.target.options[e.target.selectedIndex].text;
                          handlerDropDownChange(
                            e,
                            selectedText,
                            rowIndex,
                            "primary",
                            selectedId
                          );
                        }}
                        shouldFetchOnOpen={true}
                        isCache={true}
                        // initialSelectedValue={editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[0].contact_id : ""}
                        // defaultValue={{
                        //   id: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[0].contact_id : "",
                        //   value: editedData[rowIndex]?.assign_user ? JSON.parse(editedData[rowIndex]?.assign_user)[0].primary : "",
                        // }}

                        initialSelectedValue={
                          item.assign_user
                            ? JSON.parse(item.assign_user)[0].contact_id
                            : ""
                        }
                        defaultValue={{
                          id: item.assign_user
                            ? JSON.parse(item.assign_user)[0].contact_id
                            : "",
                          value: item.assign_user
                            ? JSON.parse(item.assign_user)[0].primary
                            : "",
                        }}
                      />
                    </td>
                    <td style={{ maxWidth: "200px" }}>
                      <GlobalDropdown
                        dropDownName={"backup1"}
                        apiEndpoint={`contact/acc-contact-dropdown/${
                          item?.account_id || accountId
                        }`}
                        idKey={"contact_id"}
                        valueKey={"contact_name"}
                        parentKey={"contact_list"}
                        onSelect={(e) => {
                          const selectedId = e.target.value;
                          const selectedText =
                            e.target.options[e.target.selectedIndex].text;
                          handlerDropDownChange(
                            e,
                            selectedText,
                            rowIndex,
                            "backup1",
                            selectedId
                          );
                        }}
                        shouldFetchOnOpen={true}
                        isCache={true}
                        initialSelectedValue={
                          item.assign_user
                            ? JSON.parse(item.assign_user)[1].contact_id
                            : ""
                        }
                        defaultValue={{
                          id: item.assign_user
                            ? JSON.parse(item.assign_user)[1].contact_id
                            : "",
                          value: item.assign_user
                            ? JSON.parse(item.assign_user)[1].backup1
                            : "",
                        }}
                      />
                    </td>

                    <td style={{ maxWidth: "200px" }}>
                      <GlobalDropdown
                        dropDownName={"backup2"}
                        apiEndpoint={`contact/acc-contact-dropdown/${
                          item?.account_id || accountId
                        }`}
                        idKey={"contact_id"}
                        valueKey={"contact_name"}
                        parentKey={"contact_list"}
                        onSelect={(e) => {
                          const selectedId = e.target.value;
                          const selectedText =
                            e.target.options[e.target.selectedIndex].text;
                          handlerDropDownChange(
                            e,
                            selectedText,
                            rowIndex,
                            "backup2",
                            selectedId
                          );
                        }}
                        shouldFetchOnOpen={true}
                        isCache={true}
                        initialSelectedValue={
                          item.assign_user
                            ? JSON.parse(item.assign_user)[2].contact_id
                            : ""
                        }
                        defaultValue={{
                          id: item.assign_user
                            ? JSON.parse(item.assign_user)[2].contact_id
                            : "",
                          value: item.assign_user
                            ? JSON.parse(item.assign_user)[2].backup2
                            : "",
                        }}
                      />
                    </td>

                    <td style={{ maxWidth: "200px" }}>
                      <GlobalDropdown
                        dropDownName={"backup3"}
                        apiEndpoint={`contact/acc-contact-dropdown/${
                          item?.account_id || accountId
                        }`}
                        idKey={"contact_id"}
                        valueKey={"contact_name"}
                        parentKey={"contact_list"}
                        onSelect={(e) => {
                          const selectedId = e.target.value;
                          const selectedText =
                            e.target.options[e.target.selectedIndex].text;
                          handlerDropDownChange(
                            e,
                            selectedText,
                            rowIndex,
                            "backup3",
                            selectedId
                          );
                        }}
                        shouldFetchOnOpen={true}
                        isCache={true}
                        initialSelectedValue={
                          item.assign_user
                            ? JSON.parse(item.assign_user)[3].contact_id
                            : ""
                        }
                        defaultValue={{
                          id: item.assign_user
                            ? JSON.parse(item.assign_user)[3].contact_id
                            : "",
                          value: item.assign_user
                            ? JSON.parse(item.assign_user)[3].backup3
                            : "",
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </table>

        <div className="row pb-3 py-5">
          <div className="col-12 content-flex-right">
            <button
              className="btn btn-danger text-uppercase"
              type="button"
              onClick={() => {
                const is_active = 0;
                handleDispatch(siteId, is_active);
                navigate(-1);
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-success text-uppercase ms-2"
              type="button"
              onClick={handleSubmit}
              disabled={loading}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditAssignAedTbl;
