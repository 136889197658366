import React, { useEffect, useState } from "react";
import { findSectionById } from "../Services";
import { useNavigate } from "react-router-dom";
import { isPermission } from "../../../../common/helper/PermissionManager";

const AccessoriesCard = ({ sections }) => {
  const [accessories, setAccessories] = useState({});
  const navigate = useNavigate();
  const id = "accessories";
  const account = findSectionById("accounts", sections);

  const navigateAccessory = () => {
    if (!account?.account_id) {
      Number(isPermission({ type: "dashboard", name: "EquipmentListing" })) ===
        1 && navigate("/Admin/accessory-listing");
    } else {
      Number(isPermission({ type: "dashboard", name: "EquipmentListing" })) ===
        1 && navigate(`/account-details/${account?.account_id}/Equipment`);
    }
  };

  useEffect(() => {
    const matched = findSectionById(id, sections);
    setAccessories(matched);
  }, [sections]);

  return (
    <div className="p-4 rounded-lg">
      <h3
        className="text-center text-lg font-bold mb-2"
        style={{ cursor: Number(accessories?.totalcount) > 0 ? "pointer" : "" }}
        onClick={() =>
          Number(accessories?.totalcount) > 0 && navigateAccessory()
        }
      >
        {accessories?.totalcount}
      </h3>
      <p className="text-center">Accessories</p>
    </div>
  );
};

export default AccessoriesCard;
