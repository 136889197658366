import React, { useEffect, useState } from "react";
import { Alert, Box, Snackbar } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SubHeading from "../../../common/components/SubHeading";

import { createTheme } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import Moment from "react-moment";
import { addNewBreadcrumbs, clearBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
import { useDispatch } from "react-redux";

const NoteListing = ({ setShowSidebar, note_api, method, type, accountId, site_id, contact_id, aed_id, fetch_key, isSearchable }) => {

    const navigate = useNavigate();
    const [openModel, setOpenModel] = React.useState(false);
    const dispatch = useDispatch()


    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    const location = useLocation();
    // const showDelete = location?.state?.showDelete;

    const handleCloseModel = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpenModel(false);
    };


    const getStatus = (data) => {
        switch (data?.active) {
            case 0:
                return <span className="text-danger">Deactive</span>;

            case 1:
                return <span className="text-success">Active</span>;

        }
    };
    const handleDetailsDispatch = (noteId, title, accountId, accountName) => {
        const BreadCrumbsObject = {
            title: "",
            tab: "",
            type: "",
        };
        const handleDispatch = () => {
            BreadCrumbsObject.title = "Note Listing";

            dispatch(
                addNewBreadcrumbs({ ...BreadCrumbsObject, url: "/Admin/Note-listing" })
            );
        };
        if (isSearchable === true) {

            handleDispatch();
        }
        BreadCrumbsObject.title = title + " Details";

        dispatch(
            addNewBreadcrumbs({
                ...BreadCrumbsObject,
                url: `/account/note-details/${noteId}`,
            })
        );
    };
    const getTitle = (data) => {

        return <span
            className="link"
            style={{ fontWeight: "bold", textDecoration: "none" }}
            onClick={() => {

                handleDetailsDispatch(
                    data.notes_id,
                    data.title

                );
                navigate(`/account/note-details/${data.notes_id}`)
            }}>{data?.title}</span>
    }
    useEffect(() => {
        if (isSearchable === true) {
            dispatch(clearBreadcrumbs());
        }
    }, [dispatch]);
    const headerColumns = [

        {
            Header: "Title",
            accessor: "title",
            width: "12%",
            Cell: ({ row }) => {
                return getTitle(row?.original)
            },
            disableSortBy: true,

        },
        {
            Header: "Related To",
            accessor: "related_to",
            width: "12%",

        }, {
            Header: "Created Date",
            accessor: "created_date",
            Cell: ({ row }) => (
                <span>
                    <Moment
                        date={row?.original.created_date}
                        format={"MM/DD/YYYY h:mm A"}
                    />
                </span>
            ),
            width: "10%",
        },
        {
            Header: "Created By ",
            accessor: "created_by",
            width: "15%",

        },

        {
            Header: "Access",
            accessor: "access",
            disableSortBy: false,
            width: "8%",
            disableSortBy: true,
        },

        {
            Header: "Status",
            accessor: "active",
            width: "8%",
            Cell: ({ row }) => {
                return getStatus(row?.original)
            },
            disableSortBy: true,

        },

    ]
    return (
        <>
            <Snackbar
                open={openModel}
                autoHideDuration={3000}
                onClose={handleCloseModel}
            >
                <Alert
                    severity="success"
                    sx={{ width: "100%" }}
                    onClose={handleCloseModel}
                >
                    Record Deleted SuccessFully!!!
                </Alert>
            </Snackbar>
            <div className="mt-4" style={{ paddingInline: "0px" }}>
                {isSearchable === true && (

                    <SubHeading
                        hideNew={true}
                        hideHierarchy={true}
                        title={"Note Listing"}
                        subHeading={true}
                    />

                )}

                <div style={{ minHeight: "84.6vh" }}>

                    { /*   <NoteListTbl
                            tableData={notesData}
                            showLoading={showLoading}
                            setShowLoading={setShowLoading}
                        />*/}

                    <NewGlobalTable
                        method={method || "GET"}

                        isSearchable={isSearchable || false}
                        isCache={true}
                        pageSize={50}

                        apiEndPoint={note_api}
                        keyAPiName={fetch_key}
                        headerColumns={headerColumns}
                    />

                </div>
            </div>

        </>
    );
};

export default NoteListing;
