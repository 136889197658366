import React, { useState } from "react";
import QuestionMark from "../../../../../common/img/QuestionMark.png";
import SiteListModel from "../../Model/SiteListModel";
import { NewModiFicationGroupIcon } from "../../../../../common/helper/icons";

const ActionsComp = ({
  index,
  crrIndex,
  formData,
  setFormData,
  section_name,
  BatteryList,
  handleInputChange,
  is_readonly = 0,
  crrFormData,
  unKnownToggleKey,
}) => {
  const [openSitelistModel, setOpenSitelistModel] = useState(false);
  const [inventoryModal, setInventoryModal] = useState(false);
  const [selectSite, setSelectedSite] = useState("");
  const [api, setApi] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  function areAllKeysUnknown(obj) {
    for (const key in obj) {
        if (obj[section_name] !== 'unknown' && obj["no_9v_spare_toggle"] !== 'unkown' &&
          obj["created_date"] == 'unknown' && obj[key] == 'unknown') {
            return false;
        }
    }
    return true;
  }

  const fillUnknown = (checkKey) => {
    // Create a new object to store the result
   let result = {};
  
    // Iterate over each key in the input object
    for (let key in checkKey) {
        // Check if the current key is "section_name"
        if (key === "section_name" || key === "index" || key === "is_spare") {
            // Preserve the original value for "section_name"
            result[key] = checkKey[key];
        } else {
            // Set the value to "unknown" for all other keys
            result[key] = "unknown";
        }
    }
    return result;
  }

  function findObjectBySectionAndIndex(sectionName, index, unkownArr) {
    for (let obj of unkownArr) {
     sectionName = sectionName === "ChargePakInformation" ? "charge_pack" :
     sectionName === "AdultPadInformation" ? "adult_pad_info" :
     sectionName === "AdultPadPakInfo" ? "adult_pad_pak_info" :
     sectionName === "PediatricPadInformation" ? "pediatric_pad_info" :
     sectionName === "PediatricPakPadInformation" ? "pediatric_pak_pad_info" : sectionName;
      if (obj.section_name === sectionName && obj.index === index && obj.is_spare === 0) {
         return obj;
       }
   }
   return {};
 }

  const handle_unknow_checkbox = (e, crrIndex, keyname, toogleKeyName) => {
    const oldData = { ...formData };
    console.log({ keyname });
    let save_obj = {};
    if (
      keyname === "has_battery" ||
      keyname === "has_battery_spare" ||
      keyname === "has_9v" ||
      keyname === "has_9v_spare" ||
      keyname === "has_installby" ||
      keyname === "has_installby_spare" ||
      keyname === "has_man" ||
      keyname === "has_man_spare" ||
      keyname === "has_10pk" ||
      keyname === "has_10pk_spare"
    ) {
      save_obj = {
        battery_type_id: e.target.checked ? "unknown" : "",
        battery_expiration: e.target.checked ? "unknown" : "",
        battery_lot: e.target.checked ? "unknown" : "",
        battery_uid: e.target.checked ? "unknown" : "",
        v9_install: e.target.checked ? "unknown" : "",
        install_before_date: e.target.checked ? "unknown" : "",
        date_installed: e.target.checked ? "unknown" : "",
        manufactured_date: e.target.checked ? "unknown" : "",
      };
    }

    if (
      keyname === "ChargePakInformation" ||
      keyname === "SpareChargePakInformation"
    ) {
      save_obj = {
        battery_type_id: e.target.checked ? "unknown" : "",
        battery_expiration: e.target.checked ? "unknown" : "",
        battery_lot: e.target.checked ? "unknown" : "",
        battery_uid: e.target.checked ? "unknown" : "",
        v9_install: e.target.checked ? "unknown" : "",
        install_before_date: e.target.checked ? "unknown" : "",
        date_installed: e.target.checked ? "unknown" : "",
        manufactured_date: e.target.checked ? "unknown" : "",
        charge_pak_part:
          keyname === "ChargePakInformation" ||
          keyname === "SpareChargePakInformation"
            ? e.target.checked
              ? "unknown"
              : ""
            : "",

        charge_pak_uid:
          keyname === "ChargePakInformation" ||
          keyname === "SpareChargePakInformation"
            ? e.target.checked
              ? "unknown"
              : ""
            : "",
        // "battery_expiration": e.target.checked ? "unknown" : "",
        // "battery_lot": e.target.checked ? "unknown" : "",
        charge_pak_pad_1_id:
          keyname === "ChargePakInformation" ||
          keyname === "SpareChargePakInformation"
            ? e.target.checked
              ? "unknown"
              : ""
            : "",
        pad_1_expiration:
          keyname === "ChargePakInformation" ||
          keyname === "SpareChargePakInformation"
            ? e.target.checked
              ? "unknown"
              : ""
            : "",
        pad_1_lot:
          keyname === "ChargePakInformation" ||
          keyname === "SpareChargePakInformation"
            ? e.target.checked
              ? "unknown"
              : ""
            : "",
        charge_pak_pad_2_id:
          keyname === "ChargePakInformation" ||
          keyname === "SpareChargePakInformation"
            ? e.target.checked
              ? "unknown"
              : ""
            : "",
        pad_2_expiration:
          keyname === "ChargePakInformation" ||
          keyname === "SpareChargePakInformation"
            ? e.target.checked
              ? "unknown"
              : ""
            : "",
        pad_2_lot:
          keyname === "ChargePakInformation" ||
          keyname === "SpareChargePakInformation"
            ? e.target.checked
              ? "unknown"
              : ""
            : "",
        battery_udi:
          keyname === "ChargePakInformation" ||
          keyname === "SpareChargePakInformation"
            ? e.target.checked
              ? "unknown"
              : ""
            : "",
        // "manufactured_date": e.target.checked ? "unknown" : "",
        install_date:
          keyname === "ChargePakInformation" ||
          keyname === "SpareChargePakInformation"
            ? e.target.checked
              ? "unknown"
              : ""
            : "",
        // "battery_type_id": e.target.checked ? "unknown" : "",
      };
    }

    if (
      keyname === "AdultPadInformation" ||
      keyname === "SpareAdultPadInfo" ||
      keyname === "AdultPadPakInfo" ||
      keyname === "SpareAdultPadPakInfo" ||
      keyname === "PediatricPadInformation" ||
      keyname === "sparePadricPadInfo" ||
      keyname === "PediatricPakPadInformation" ||
      keyname === "sparePadricPakPad"
    ) {
      save_obj = {
        pad_part: e.target.checked ? "unknown" : "",
        pad_expiration: e.target.checked ? "unknown" : "",
        pad_lot: e.target.checked ? "unknown" : "",
        pad_udi: e.target.checked ? "unknown" : "",
        pad_type_id: e.target.checked ? "unknown" : "",
      };
    }

    if (
      Array.isArray(oldData[keyname]) &&
      Array.isArray(oldData[keyname][crrIndex])
    ) {
      // Initialize newArr with save_obj replicated for each element in the array
      const newArr = oldData[keyname][crrIndex].map(() => ({
        ...save_obj,
        [toogleKeyName]: e.target.checked,
      }));

      // Update the specific index with the new array
      oldData[keyname][crrIndex] = newArr;

      // Update the form data with the new state
      setFormData(oldData);
    } else if (
      oldData[keyname] &&
      typeof oldData[keyname][crrIndex] === "object"
    ) {
      // If oldData[keyname][crrIndex] is an object, update it directly
      oldData[keyname][crrIndex] = {
        ...save_obj,
        [toogleKeyName]: e.target.checked,
      };

      // Update the form data with the new state
      setFormData(oldData);
    } else {
      console.error(
        "Unexpected data structure: oldData[keyname][crrIndex] should be an array or object."
      );
    }
  };

  const handleCheckboxClick = (crrIndex) => {
    setIsChecked((prevState) => !prevState);
    const e = { target: { checked: !isChecked } };
    handle_unknow_checkbox(e, crrIndex, section_name, unKnownToggleKey);
  };
  // BatteryList
  return (
    <>
      <div className="col form-group" style={{ maxWidth: "100px" }}>
        <label htmlFor="">Actions</label>
        <div>
          <span
            onClick={isChecked == true ? undefined : setOpenSitelistModel}
            style={{ cursor: "pointer", marginRight: "2px" }}
            disabled={isChecked == true}
          >
            {" "}
            <NewModiFicationGroupIcon />{" "}
          </span>
          <span>
            <img
              src={QuestionMark}
              alt=""
              onClick={() => handleCheckboxClick(crrIndex)}
              style={{ height: "20px", width: "20px", marginLeft: "5px", cursor: "pointer" }}
            />
          </span>

          <img src="" />
        </div>
      </div>
      {openSitelistModel && (
        <SiteListModel
          openSitelistModel={openSitelistModel}
          setOpenSitelistModel={setOpenSitelistModel}
          setInventoryModal={setInventoryModal}
          section_name={section_name}
          Bindex={crrIndex}
          index={index}
          AedFormData={formData}
          setNewFormData={setFormData}
          contact_id={"1"}
          secName={section_name}
          inspection_by={"2"}
          crrFormData={crrFormData}
          BatteryList={BatteryList}
        />
      )}
    </>
  );
};

export default ActionsComp;
